import React, { createRef } from 'react';
import { cloneDeep, get, isEqual, set } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import findPathById from '../../../../../Utils/objectManipulation/findPathById';
import findYByX from '../../../../../Utils/objectManipulation/findYByX';
import findPath from '../../../../../Utils/objectManipulation/findPath';
import duplicateWithSameParent from '../../../../../Utils/objectManipulation/duplicateWithSameParent';
import NeoFormUse from '../../../NeoFormUse';
import FieldDynamicBoxes from '../../../NeoFields/DynamicByApi/Boxes';
import FieldSimpleTabs from '../../../NeoFields/Tabs/basic';
import FieldJsonLogicEditor from '../../../NeoFields/JsonLogicEditor';
import FieldJsonEditor from '../../../NeoFields/JsonEditor';
import FieldAlertMessage from '../../../NeoFields/AlertMessage';
import FieldHtmlEditor from '../../../NeoFields/Html/HtmlEditor';
import FieldHtmlParser from '../../../NeoFields/Html/HtmlParser';
import Invisible from '../../../NeoFields/Invisible';
import Dependency from './editTabs/dependency';

const editAction = (
  localMetaSpec,
  setLocalMetaSpec,
  importFieldBuilderSchemas,
  setBuilderZoom,
  setDialogConfig,
  setDialogOpen,
  setAtomSnackbar
) => {
  const { t } = useTranslation();
  const dependencyTab = Dependency();

  const editElement = async (field, menu, initialization) => {
    let fieldForms = null;

    const actionEdit = (
      currentField = field,
      currentMetaSpec = localMetaSpec
    ) => {
      fieldForms = dependencyTab.init(currentField, currentMetaSpec);

      if (!fieldForms) {
        fieldForms = currentField?.blueprint?.actions.find(
          (x) => x.id === 'edit'
        );
      }

      const handleSubmit = (formData, JsonSchema) => {
        if (
          !isEqual(fieldForms.formData, formData) ||
          !isEqual(currentMetaSpec, localMetaSpec)
        ) {
          fieldForms.formData = formData;
          fieldForms.jsonSchema = JsonSchema;

          const newMetaSpec = cloneDeep(currentMetaSpec);
          newMetaSpec.updateTimestamp = Date.now();
          setLocalMetaSpec(newMetaSpec);
          setDialogOpen(false);

          if (initialization) {
            setAtomSnackbar((prevAtomSnackbar) => ({
              ...prevAtomSnackbar,
              open: true,
              text: t('Snackbar.AddField'),
              severity: 'success',
            }));
          } else {
            setAtomSnackbar((prevAtomSnackbar) => ({
              ...prevAtomSnackbar,
              open: true,
              text: t('Snackbar.EditField'),
              severity: 'success',
            }));
          }
        } else {
          setDialogOpen(false);
          setAtomSnackbar((prevAtomSnackbar) => ({
            ...prevAtomSnackbar,
            open: true,
            text: t('Snackbar.EditFieldNoChange'),
            severity: 'info',
          }));
        }
      };

      const handleValidation = (_formData, _errors, formDataReceived) => {
        if (_formData?.tabs?.data?.id !== currentField.blueprint.id) {
          // TODO: vérifier dans currentField.blueprint.id si il contient le _formData?.tabs?.data?.id
          const objectFounds = findYByX(
            currentMetaSpec?.menus?.formBuilder,
            'id',
            _formData.tabs.data.id
          );

          // Supprime le potentiel doublon dans le cas d'un champ partagé
          if (objectFounds?.length) {
            objectFounds.forEach((element, index) => {
              if (element?.content?.method) {
                delete objectFounds[index];
              }
            });
          }

          // On ajoute le chemin actuel (= chemin ou l'on essaie d'ajouter un nouveau champ)
          // dans la liste des chemins à vérifier pour le contrôle de l'id,
          // car c'est surtout à cet endroit qu'on ne peut avoir 2 champs ayant un même id
          if (currentField.blueprint.group !== 'shared') {
            const localPath = findYByX(
              currentMetaSpec?.menus?.formBuilder,
              'id',
              _formData.tabs.data.id
            );
            if (localPath.length > 0) {
              objectFounds.push(localPath[0]);
            }
          }

          const duplicate = duplicateWithSameParent(objectFounds);
          if (initialization && duplicate) {
            _errors.tabs.data.id.addError(
              'Cet identifiant unique est déjà attribué'
            );
          } else if (
            !initialization &&
            duplicate &&
            formDataReceived?.tabs?.data?.id !== _formData?.tabs?.data?.id
          ) {
            _errors.tabs.data.id.addError(
              'Cet identifiant unique est déjà attribué'
            );
          }
        }

        if (
          _formData?.tabs?.data?.id &&
          /\[|\]|\.|@/gi.test(_formData.tabs.data.id)
        ) {
          _errors.tabs.data.id.addError(
            'Votre identifiant ne peut pas contenir les caractères suivants : . ou ] ou [ ou @'
          );
        }

        return _errors;
      };

      const handleChange = (
        formData,
        currentJSchema,
        setJSchema,
        currentUiSchema,
        setCurrentUiSchema,
        setFormData,
        oldFormData
      ) => {
        const cpySchema = cloneDeep(currentJSchema);
        const originalFormData = cloneDeep(formData);

        const getJsonLogicDatas = () => {
          const posibilites = {};
          currentField.blueprint.elements.map((element) => {
            const elementFormData = element?.blueprint?.actions.find(
              (x) => x.id === 'edit'
            )?.formData?.tabs;

            let returnId = element?.blueprint.id;
            if (elementFormData?.data?.id) returnId = elementFormData?.data?.id;

            if (elementFormData?.data?.enum) {
              const posibilite = elementFormData?.data?.enum.map(
                (posibilitie, index) => `${index} -> ${posibilitie}`
              );
              posibilites[returnId] = posibilite;
            } else if (elementFormData?.data?.enumNames) {
              const posibilite = elementFormData?.data?.enumNames.map(
                (posibilitie, index) => {
                  if (index === 0) return `true -> ${posibilitie}`;
                  return `false -> ${posibilitie}`;
                }
              );
              posibilites[returnId] = posibilite;
            } else if (elementFormData) {
              posibilites[returnId] = '';
              return returnId;
            }

            if (elementFormData) {
              return returnId;
            }
            return null;
          });
          return posibilites;
        };

        // SURCHARGE NOTATION
        if (
          formData &&
          formData?.tabs?.rating?.activated &&
          findPath(currentJSchema, 'rating')
        ) {
          if (currentField.blueprint.elements) {
            // CHAMP PARENT AYANT DES ELEMENTS DONC JSON LOGIC
            const posibilites = getJsonLogicDatas();

            const ratingPath = findPath(currentJSchema, 'rating');
            ratingPath.splice(-1, 1);
            const ratingObject = get(cpySchema, ratingPath);
            if (
              !ratingObject.properties.jsonLogicRateFormula.jsonLogicData ||
              !isEqual(
                posibilites,
                ratingObject.properties.jsonLogicRateFormula.jsonLogicData
              )
            ) {
              ratingObject.properties = {
                ...ratingObject.properties,
                activated: {
                  title: 'Activer la notation',
                  type: 'boolean',
                },
                jsonLogicRateFormula: {
                  id: 'jsonLogicRateFormula',
                  title: '',
                  type: 'string',
                  jsonLogicData: posibilites,
                },
              };
              setJSchema(cpySchema);
            }
          }
        } else if (formData && findPath(currentJSchema, 'rating')) {
          if (
            formData?.tabs?.rating?.activated === false ||
            formData?.tabs?.rating?.activated === undefined
          ) {
            // Ne pas toujours appeler
            const ratingPath = findPath(currentJSchema, 'rating');
            ratingPath.splice(-1, 1);
            const ratingObject = get(cpySchema, ratingPath);
            if (!ratingObject?.properties?.activated) {
              ratingObject.properties = {
                activated: {
                  title: 'Activer la notation',
                  type: 'boolean',
                },
              };
              setJSchema(cpySchema);
            }
          }
        }

        // SURCHARGE LITTERAIRE
        if (
          formData &&
          formData?.tabs?.literary?.activated &&
          findPath(currentJSchema, 'literary')
        ) {
          if (currentField.blueprint.elements) {
            const posibilites = getJsonLogicDatas();

            const literaryPath = findPath(currentJSchema, 'literary');
            literaryPath.splice(-1, 1);
            const literaryObject = get(cpySchema, literaryPath);
            if (
              !literaryObject.properties.jsonLogicLiteraryFormula
                .jsonLogicData ||
              !isEqual(
                posibilites,
                literaryObject.properties.jsonLogicLiteraryFormula.jsonLogicData
              )
            ) {
              literaryObject.properties = {
                ...literaryObject.properties,
                activated: {
                  title: 'Activer le rendu textuel',
                  type: 'boolean',
                },
                jsonLogicLiteraryFormula: {
                  id: 'jsonLogicLiteraryFormula',
                  title: '',
                  type: 'string',
                  jsonLogicData: posibilites,
                },
              };
              setJSchema(cpySchema);
            }
          }
        } else if (formData && findPath(currentJSchema, 'literary')) {
          if (
            formData?.tabs?.literary?.activated === false ||
            formData?.tabs?.literary?.activated === undefined
          ) {
            // Ne pas toujours appeler
            const literaryPath = findPath(currentJSchema, 'literary');
            literaryPath.splice(-1, 1);
            const literaryObject = get(cpySchema, literaryPath);
            if (!literaryObject?.properties?.activated) {
              literaryObject.properties = {
                activated: {
                  title: 'Activer le rendu textuel',
                  type: 'boolean',
                },
              };
              setJSchema(cpySchema);
            }
          }
        }

        // DEPENDENCY - HANDLE CHANGE
        dependencyTab.handleChange(
          cpySchema,
          formData,
          currentJSchema,
          setJSchema,
          currentUiSchema,
          setCurrentUiSchema,
          setFormData,
          oldFormData
        );
        // /DEPENDENCY - HANDLE CHANGE

        // GESTION ACCESS
        if (formData && formData?.tabs?.access?.accessActivation === 'Yes') {
          if (cpySchema?.properties?.tabs?.properties?.access?.properties) {
            cpySchema.properties.tabs.properties.access.dependencies = {};
            currentMetaSpec.menus.formBuilder?.roles?.map((element) => {
              if (
                cpySchema.properties.tabs.properties.access?.dependencies &&
                cpySchema.properties.tabs.properties.access.dependencies
                  ?.accessActivation?.oneOf?.[1]?.properties
              ) {
                cpySchema.properties.tabs.properties.access.dependencies.accessActivation.oneOf[1].properties =
                  {
                    ...cpySchema.properties.tabs.properties.access.dependencies
                      .accessActivation.oneOf[1].properties,
                    [element]: {
                      type: 'object',
                      title: element,
                      properties: {
                        read: {
                          type: 'boolean',
                          title: t('NeoBuilder.Access.ReadPermission'),
                        },
                        write: {
                          type: 'boolean',
                          title: t('NeoBuilder.Access.WritePermission'),
                        },
                      },
                    },
                  };
              } else {
                cpySchema.properties.tabs.properties.access.dependencies = {
                  accessActivation: {
                    oneOf: [
                      {
                        properties: {
                          accessActivation: {
                            enum: ['No'],
                          },
                        },
                      },
                      {
                        properties: {
                          accessActivation: {
                            enum: ['Yes'],
                          },
                          [element]: {
                            type: 'object',
                            title: element,
                            properties: {
                              read: {
                                type: 'boolean',
                                title: t('NeoBuilder.Access.ReadPermission'),
                              },
                              write: {
                                type: 'boolean',
                                title: t('NeoBuilder.Access.WritePermission'),
                              },
                            },
                          },
                        },
                      },
                    ],
                  },
                };
              }
              return null;
            });
          }

          let accessChange = false;
          // eslint-disable-next-line no-restricted-syntax
          for (const roleName of Object.getOwnPropertyNames(
            currentMetaSpec.menus.formBuilder?.permissions
          )) {
            if (!formData.tabs.access?.[roleName]) {
              accessChange = true;
              // eslint-disable-next-line no-param-reassign
              formData.tabs.access = {
                ...formData.tabs.access,
                [roleName]:
                  currentMetaSpec.menus.formBuilder?.permissions[roleName],
              };
            }
          }
          if (accessChange) {
            setJSchema(cpySchema);
          }
        }

        if (
          !isEqual(originalFormData, formData) ||
          !isEqual(originalFormData, oldFormData)
        ) {
          setFormData(formData);
        }
      };

      if (Object.entries(fieldForms.jsonSchema).length > 0) {
        if (initialization && !fieldForms?.formData?.tabs?.data?.id) {
          if (fieldForms?.formData?.tabs?.data) {
            fieldForms.formData = {
              ...fieldForms.formData,
              tabs: {
                ...fieldForms.formData?.tabs,
                data: {
                  ...fieldForms.formData?.tabs?.data,
                  id: uuidv4(),
                },
              },
            };
          } else if (fieldForms?.formData?.tabs) {
            fieldForms.formData = {
              ...fieldForms.formData,
              tabs: {
                ...fieldForms.formData?.tabs,
                data: {
                  id: uuidv4(),
                },
              },
            };
          } else {
            fieldForms.formData = {
              ...fieldForms.formData,
              tabs: {
                data: {
                  id: uuidv4(),
                },
              },
            };
          }
        }

        const submitFormRef = createRef();

        setDialogConfig({
          title: '',
          content: (
            <NeoFormUse
              builder
              customSubmit={
                <Button
                  ref={submitFormRef}
                  style={{ display: 'none' }}
                  type='submit'
                >
                  submitForm
                </Button>
              }
              extensions={[]}
              fields={{
                NeoSimpleTabs: FieldSimpleTabs,
                NeoJsonLogicEditor: FieldJsonLogicEditor,
                NeoJsonEditor: FieldJsonEditor,
                NeoDynamicBoxes: FieldDynamicBoxes,
                NeoAlertMessage: FieldAlertMessage,
                NeoHtmlEditor: FieldHtmlEditor,
                NeoHtmlParser: FieldHtmlParser,
                NeoInvisible: Invisible,
              }}
              formDataReceived={fieldForms.formData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleValidation={handleValidation}
              JsonSchema={fieldForms.jsonSchema}
              key={uuidv4()}
              UiSchema={fieldForms.uiSchema}
            />
          ),
          actions: (
            <>
              <Button
                color='primary'
                onClick={() => {
                  if (initialization) {
                    const currentFieldPath = findPathById(
                      currentMetaSpec.menus.formBuilder,
                      currentField.blueprint.id
                    );
                    currentFieldPath.splice(-3, 3);
                    const copyCurrentFieldParent = get(
                      currentMetaSpec.menus.formBuilder,
                      currentFieldPath
                    );
                    const removeIndex = copyCurrentFieldParent
                      .map((item) => item.blueprint.id)
                      .indexOf(currentField.blueprint.id);
                    copyCurrentFieldParent.splice(removeIndex, 1);
                    // eslint-disable-next-line no-param-reassign
                    currentMetaSpec.updateTimestamp = Date.now();
                    setLocalMetaSpec(currentMetaSpec);
                  }
                  setDialogOpen(false);
                }}
              >
                {t('Global.Cancel')}
              </Button>
              <Button
                color='primary'
                onClick={() => {
                  submitFormRef.current.click();
                }}
              >
                {t('Global.Submit')}
              </Button>
            </>
          ),
        });
        setDialogOpen(true);
      }

      // Si le champ peut contenir des éléments enfants
      if (currentField.blueprint.elements && initialization) {
        // on vérifie si il s'agit d'un champ partagé ou non
        const preEditAction = currentField.blueprint?.actions.find(
          (x) => x.id === 'preedit'
        );

        // auquel cas on ne zoom pas sur un champ partagé "par référence"
        if (!preEditAction || preEditAction?.formData?.method !== 'hosted') {
          setBuilderZoom(currentField);
        }
      }
      return null;
    }; // fin actionEdit

    const newMetaSpec = cloneDeep(localMetaSpec);

    if (field?.blueprint?.group === 'shared') {
      let preEditAction = field.blueprint?.actions.find(
        (x) => x.id === 'preedit'
      );

      const {
        jsonSchema,
        uiSchema,
        allVersionsSchemas,
        handleChangeMethodOrVersion,
      } = await importFieldBuilderSchemas(field.id);

      let handleChangeLocal = () => {};
      if (handleChangeMethodOrVersion) {
        handleChangeLocal = handleChangeMethodOrVersion;
      }

      if (initialization && !preEditAction) {
        // création d'une action action preEdit
        preEditAction = {
          id: 'preedit',
          formData: {},
          jsonSchema,
          uiSchema,
        };
      } else {
        preEditAction.jsonSchema = jsonSchema;
        preEditAction.uiSchema = uiSchema;
      }

      const submitFormRef = createRef();

      const handleSubmit = (formData, _jsonSchema) => {
        if (
          (formData?.version || formData?.version === 0) &&
          formData?.method
        ) {
          // si différent d'un précédent choix
          let preEditChanged = false;
          if (
            preEditAction?.formData &&
            !isEqual(preEditAction.formData, formData)
          ) {
            preEditAction.formData = formData;
            preEditChanged = true;
          }

          let newSchema = null;
          if (preEditChanged) {
            newSchema = allVersionsSchemas.find(
              (x) => x.schema.version === formData.version
            )?.schema;

            newSchema.blueprint.actions.unshift(preEditAction);

            const localEditAction = newSchema?.blueprint?.actions?.find(
              (x) => x.id === 'edit'
            );

            // suivant la méthode sélectionnée
            if (formData.method === 'hosted') {
              // utilisation de l'id saisi
              if (localEditAction?.formData?.tabs?.data?.id) {
                // eslint-disable-next-line no-param-reassign
                localEditAction.formData.tabs.data.id = formData?.id;
              }

              // suppression des onglets "data", "validation", "notation" et "rendu textuel" si présent
              delete localEditAction?.jsonSchema?.properties?.tabs?.properties
                ?.data;
              delete localEditAction?.jsonSchema?.properties?.tabs?.properties
                ?.validation;
              delete localEditAction?.jsonSchema?.properties?.tabs?.properties
                ?.rating;
              delete localEditAction?.jsonSchema?.properties?.tabs?.properties
                ?.literary;

              // suppression des éléments constituant le champ partagé
              newSchema.blueprint.elements = [];

              // on ne garde que les actions utiles dans ce cas
              const toKeep = ['preedit', 'edit', 'move', 'delete'];
              const safeActions = newSchema.blueprint.actions.filter((action) =>
                toKeep.includes(action.id)
              );
              newSchema.blueprint.actions = safeActions;
            }

            const oldId = field.blueprint.id;
            const oldElement = findYByX(
              localMetaSpec.menus.formBuilder,
              'id',
              oldId
            );
            if (oldElement.length === 1) {
              oldElement[0].path.splice(-2, 2);
              newSchema.blueprint.id = oldId;
              set(newMetaSpec.menus.formBuilder, oldElement[0].path, newSchema);
              newMetaSpec.updateTimestamp = Date.now();
            }
          }
          if (newSchema) {
            actionEdit(newSchema, newMetaSpec);
          } else {
            actionEdit();
          }
        }
      };

      setDialogConfig({
        title: '',
        content: (
          <NeoFormUse
            builder
            customSubmit={
              <Button
                ref={submitFormRef}
                style={{ display: 'none' }}
                type='submit'
              >
                submitForm
              </Button>
            }
            extensions={[]}
            fields={{
              NeoSimpleTabs: FieldSimpleTabs,
              NeoJsonLogicEditor: FieldJsonLogicEditor,
              NeoJsonEditor: FieldJsonEditor,
              NeoAlertMessage: FieldAlertMessage,
              NeoHtmlEditor: FieldHtmlEditor,
              NeoHtmlParser: FieldHtmlParser,
              NeoInvisible: Invisible,
            }}
            formDataReceived={preEditAction.formData}
            handleChange={handleChangeLocal}
            handleSubmit={handleSubmit}
            JsonSchema={preEditAction.jsonSchema}
            UiSchema={preEditAction.uiSchema}
          />
        ),
        actions: (
          <>
            <Button
              color='primary'
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              {t('Global.Cancel')}
            </Button>
            <Button
              color='primary'
              onClick={() => {
                submitFormRef.current.click();
              }}
            >
              {t('Global.Submit')}
            </Button>
          </>
        ),
      });
      setDialogOpen(true);
    } else {
      return actionEdit();
    }
    return null;
  }; // fin editElement

  return editElement;
};

export default editAction;
