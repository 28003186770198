/**
 * CTF: Import d'un formulaire
 */
export default ({ method }) => {
  let flagName = null;

  if (method === 'import') {
    flagName = 'importForm';
  }

  return flagName;
};
