import createClient from './createClient';
import createForm from './createForm';
import formNomPrenom from './formNomPrenom';
import exportForm from './exportForm';
import importForm from './importForm';
import dependanceSimple from './dependanceSimple';
import dependanceProfonde from './dependanceProfonde';
import referentielDynamique from './referentielDynamique';
import autocompletion from './autocompletion';
import exportNewField from './exportNewField';
import ajoutChampClient from './ajoutChampClient';
import publishForm from './publishForm';
import useSharedFieldByReference from './useSharedFieldByReference';
import useSharedFieldByCopy from './useSharedFieldByCopy';
import addRoleToForm from './addRoleToForm';
import useRoleOnForm from './useRoleOnForm';

const FlagsNames = {
  createClient: "Création d'un client",
  createForm: "Création d'un formulaire",
  formNameSurname: 'Formulaire Nom/Prénom',
  formDependencie: 'Dépendance simple',
  exportForm: 'Exporter un formulaire',
  importForm: 'Importer un formulaire',
  formDeepDependency: 'Dépendance profonde',
  dynamicRef: 'Référentiel dynamique',
  dynamicAutocompletion: 'Autocomplétion dynamique',
  exportNewField: 'Partager un champ',
  addSharedFieldToClient: 'Ajouter un champ partagé à un client',
  publishForm: 'Publier un formulaire',
  useSharedFieldByReference: 'Utiliser un champ partagé par référence',
  useSharedFieldByCopy: 'Utiliser un champ partagé par copie',
  addRoleToForm: `Ajout d'un rôle dans un formulaire`,
  useRoleOnForm: 'Utiliser un rôle dans un formulaire',
};

const Flags = {
  createClient: '6d17d970-e368-4b95-accd-99568278dc29',
  createForm: 'maintenantIlFautEditerCeFormulaire',
  formNameSurname: 'f3d5f193-bcaa-4629-b6b1-cd7820534a24',
  formDependencie: '954e2b38-c325-43fd-8bae-1abd68dc3b37',
  exportForm: 'BravoExportFonctionnel',
  importForm: 'BravoVousAvezRecupereVotreTravail',
  formDeepDependency: 'bbac6497-81d1-4f50-9fdc-86aa689cf1fd',
  dynamicRef: '57c76047-9d3b-4966-aed0-19e130e2e33e',
  dynamicAutocompletion: 'a915277e-5c94-46f4-9353-e5142d6c8bc6',
  exportNewField: '6a6416ae-1e79-4d1f-a57c-293035babb71',
  addSharedFieldToClient: 'e938676d-0354-4abc-bb41-950b3bf66644',
  publishForm: '26031757-8eba-49e5-941f-8f9a7b207ec6',
  useSharedFieldByReference: 'e259b731-2416-40b6-b428-caa35be0c446',
  useSharedFieldByCopy: 'c0ef590a-5c15-4645-8f56-127d167964bf',
  addRoleToForm: 'ed8d1ce3-2800-461d-b0ca-1bd48802d2a3',
  useRoleOnForm: 'bc979446-3ba9-49af-a756-c7a0b2703223',
};

const Challenges = {
  onCreateProduct: [createClient, createForm],
  onEditProduct: [ajoutChampClient],
  onSaveForm: [
    formNomPrenom,
    dependanceSimple,
    dependanceProfonde,
    referentielDynamique,
    autocompletion,
    useSharedFieldByReference,
    useSharedFieldByCopy,
    addRoleToForm,
    useRoleOnForm,
  ],
  onExportForm: [exportForm, importForm],
  onPublishForm: [publishForm],
  onExportField: [exportNewField],
};

export { FlagsNames, Flags };

export default Challenges;
