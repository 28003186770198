import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

function Copyright() {
  const { t } = useTranslation();
  return (
    <Typography align='center' color='textSecondary' variant='body2'>
      {t('Global.Copyright')}
      {' © '}
      <Link color='inherit' href='https://www.neolink.link/'>
        Neolink
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default Copyright;
