/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { isEqual } from 'lodash';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';

import { useRecoilValue } from 'recoil';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import currentFormAtom from '../../../../../../Atoms/currentForm';

import safelyParseJSON from '../../../../../../Utils/safelyParseJSON';
import { getOptionsAsync } from '../../../../../../Utils/engine/triggerApi';

const useStyles = makeStyles({
  mobileCheckbox: {
    paddingBottom: 4,
    paddingTop: 4,
  },
  skeletonBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
  },
  skeletonCheckBox: {
    marginLeft: 8,
    marginRight: 16,
  },
  skeletonCheckLabel: {
    marginRight: 8,
  },
  accordionClosed: {
    marginTop: 16,
  },
  accordionSummary: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  chip: {
    margin: 4,
  },
});

const selectValue = (value, selected, all) => {
  const at = all.indexOf(value);
  const updated = selected.slice(0, at).concat(value, selected.slice(at));

  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
};

const deselectValue = (value, selected) => selected.filter((v) => v !== value);

const CheckboxesWidget = ({
  schema,
  id,
  uiSchema,
  disabled,
  formData,
  autofocus,
  readonly,
  required,
  onChange,
  errorSchema,
  onBlur,
  onFocus,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const notMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const firstRender = useRef(true);
  const currentForm = useRecoilValue(currentFormAtom);
  const [enumOptions, setEnumOptions] = useState([]);
  const [currentValue, setCurrentvalue] = useState(
    safelyParseJSON(formData) ?? []
  );
  const [checkBoxGroup, setCheckBoxGroup] = useState([]);
  const [defaultCheckBoxGroup, setDefaultCheckBoxGroup] = useState({
    key: 0,
    value: '',
    manualOpen: currentValue.length > 0 ? false : undefined,
  });
  const minimumElements = schema?.['@validation']?.minimum ?? -1;
  const maximumElements = schema?.['@validation']?.maximum ?? Infinity;

  const [isLoading, setIsLoading] = useState(false);

  const preSelectValues = (preCheckPropertie, options) => {
    if (preCheckPropertie && options.length > 0 && currentValue.length === 0) {
      const all = options.map((option) => `${option.id}`);

      let tmp = currentValue;

      options.map(async (option) => {
        if (option?.checked && tmp.length < maximumElements) {
          tmp = selectValue(`${option.id}`, tmp, all);
        }
      });
      if (!isEqual(tmp, currentValue)) {
        setCurrentvalue(tmp);
        onChange(tmp, errorSchema, 2);
      }
    }
  };
  const filterCurrentValues = (filteredOptions) => {
    if (filteredOptions.length > 0) {
      const all = filteredOptions.map((option) => `${option.id}`);
      setCurrentvalue(currentValue.filter((value) => all.includes(`${value}`)));
    }
  };

  const findGroups = (options, groupNames) => {
    const groups = checkBoxGroup;
    options.forEach((option) => {
      if (
        option?.group &&
        (groups.length === 0 ||
          groups.filter((g) => g.key === option?.group).length === 0)
      ) {
        if (groupNames) {
          groups.push({
            key: option.group,
            value: groupNames.filter((gn) => gn?.key === option.group)
              ? groupNames.filter((gn) => gn?.key === option.group)[0].value
              : option.group,
            manualOpen: currentValue.length > 0 ? false : undefined,
          });
        } else {
          groups.push({
            key: option.group,
            value: option.group,
            manualOpen: currentValue.length > 0 ? false : undefined,
          });
        }
      }
    });
    setCheckBoxGroup(groups);
    if (groups.length === 0) {
      setDefaultCheckBoxGroup({
        ...defaultCheckBoxGroup,
        manualOpen: currentValue.length > 0 ? false : undefined,
      });
    }
  };

  const initOptions = () => {
    getOptionsAsync(schema['@dynamicData']).then((filteredOptions) => {
      setEnumOptions(filteredOptions);
      filterCurrentValues(filteredOptions);
      if (schema['@dynamicData']?.bindDefaultCheckedTo?.length > 0) {
        preSelectValues(
          schema['@dynamicData']?.bindDefaultCheckedTo,
          filteredOptions
        );
      }
      if (schema['@dynamicData']?.bindGroupTo?.length > 0) {
        findGroups(filteredOptions, schema['@dynamicData']?.groupNames);
      }

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (
      schema?.['@dynamicData'] &&
      schema['@dynamicData']?.['@paramsAtFetch'] &&
      schema['@dynamicData']?.['@fetched']
    ) {
      setEnumOptions(schema['@dynamicData']['@fetched']);
      filterCurrentValues(schema['@dynamicData']['@fetched']);
      if (schema['@dynamicData']?.bindDefaultCheckedTo?.length > 0) {
        preSelectValues(
          schema['@dynamicData']?.bindDefaultCheckedTo,
          schema['@dynamicData']['@fetched']
        );
      }
      if (schema['@dynamicData']?.bindGroupTo?.length > 0) {
        findGroups(
          schema['@dynamicData']['@fetched'],
          schema['@dynamicData']?.groupNames
        );
      }
    }
    if (schema['@dynamicData']?.['@isFetching'] === true) {
      setIsLoading(true);
    } else if (schema['@dynamicData']?.['@isFetching'] === false) {
      setIsLoading(false);
    }
  }, [currentForm.dynamicData.updatedTimestamp]);

  useEffect(() => {
    setIsLoading(true);
    initOptions();
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setIsLoading(true);
    setCurrentvalue([]);
    initOptions();
  }, [schema['@dynamicData']?.url]);

  const _onChange =
    (option) =>
    ({ target: { checked } }) => {
      // eslint-disable-next-line no-shadow
      const all = enumOptions.map((option) => `${option.id}`);

      if (checked) {
        setCurrentvalue(selectValue(`${option.id}`, currentValue, all));
        onChange(
          selectValue(`${option.id}`, currentValue, all),
          errorSchema,
          2
        );
      } else {
        setCurrentvalue(deselectValue(`${option.id}`, currentValue));
        onChange(deselectValue(`${option.id}`, currentValue), errorSchema, 2);
      }
    };

  // eslint-disable-next-line no-shadow
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  // eslint-disable-next-line no-shadow
  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  const shouldDisableCheckbox = useCallback(
    (value) =>
      currentValue.length >= maximumElements &&
      currentValue.indexOf(value) === -1,
    [currentValue, maximumElements]
  );

  const shouldTriggerError = useCallback(() => {
    if (
      // (required && currentValue.length === 0) ||
      currentValue.length < minimumElements ||
      currentValue.length > maximumElements
    ) {
      return true;
    }
    return false;
  }, [currentValue.length, minimumElements, required]);

  const checkBoxWithGroup = (groups) => {
    const expandAccordion = (manualOpen, groupIndex = false) => {
      let currentState = false;
      if (manualOpen === true) {
        currentState = true;
      }
      if (
        currentValue.length < maximumElements &&
        (manualOpen === true || manualOpen === undefined) &&
        (!groupIndex || groupIndex === 0)
      ) {
        currentState = true;
      }
      return currentState;
    };

    const chips = () =>
      enumOptions.map((option, index) => {
        const checked =
          currentValue && currentValue.indexOf(`${option.id}`) !== -1;
        if (checked) {
          const label =
            option.label.length > 20
              ? `${option.label.substring(0, 20)}...`
              : option.label;
          return (
            <Zoom in>
              <Chip
                className={classes.chip}
                color='secondary'
                icon={<CheckIcon />}
                key={index}
                label={label}
                size='small'
              />
            </Zoom>
          );
        }
        return null;
      });

    if (groups.length > 0) {
      return groups.map((group, groupIndex) => {
        const expandIt = expandAccordion(group.manualOpen, groupIndex);
        return (
          <Accordion
            className={!expandIt ? classes.accordionClosed : null}
            expanded={expandIt}
            onChange={(_e, expended) => {
              setCheckBoxGroup((checkBoxGroupValue) => {
                const newValue = [...checkBoxGroupValue];
                newValue[groupIndex].manualOpen = expended;
                return newValue;
              });
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box className={classes.accordionSummary}>
                {!expandIt ? (
                  <>
                    <Box>
                      <Typography>{group.value}</Typography>
                      <Typography variant='caption'>
                        Afficher la liste des critères
                      </Typography>
                    </Box>
                    <Box>{chips()}</Box>
                  </>
                ) : (
                  <Box>
                    <Typography>{group.value}</Typography>
                    <Typography variant='caption'>
                      Masquer la liste des critères
                    </Typography>
                  </Box>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {enumOptions.map((option, index) => {
                  if (option?.group === group.key) {
                    const checked =
                      currentValue &&
                      currentValue.indexOf(`${option.id}`) !== -1;
                    const checkbox = (
                      <Checkbox
                        autoFocus={autofocus && index === 0}
                        checked={checked}
                        disabled={
                          disabled ||
                          readonly ||
                          shouldDisableCheckbox(`${option.id}`)
                        }
                        id={`${id}_${index}`}
                        onBlur={_onBlur}
                        onChange={_onChange(option)}
                        onFocus={_onFocus}
                      />
                    );
                    return (
                      <FormControlLabel
                        className={!notMobile ? classes.mobileCheckbox : ''}
                        control={checkbox}
                        key={index}
                        label={option.label}
                      />
                    );
                  }
                  return null;
                })}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        );
      });
    }
    const expandIt = expandAccordion(defaultCheckBoxGroup.manualOpen);
    return (
      <Accordion
        className={!expandIt ? classes.accordionClosed : null}
        expanded={expandIt}
        onChange={(_e, expended) => {
          setDefaultCheckBoxGroup({
            ...defaultCheckBoxGroup,
            manualOpen: expended,
          });
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box className={classes.accordionSummary}>
            {!expandIt ? (
              <>
                <Typography variant='caption'>
                  Afficher la liste des critères
                </Typography>
                <Box>{chips()}</Box>
              </>
            ) : (
              <Typography variant='caption'>
                Masquer la liste des critères
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {enumOptions.map((option, index) => {
              const checked =
                currentValue && currentValue.indexOf(`${option.id}`) !== -1;
              const checkbox = (
                <Checkbox
                  autoFocus={autofocus && index === 0}
                  checked={checked}
                  disabled={
                    disabled ||
                    readonly ||
                    shouldDisableCheckbox(`${option.id}`)
                  }
                  id={`${id}_${index}`}
                  onBlur={_onBlur}
                  onChange={_onChange(option)}
                  onFocus={_onFocus}
                />
              );
              return (
                <FormControlLabel
                  className={!notMobile ? classes.mobileCheckbox : ''}
                  control={checkbox}
                  key={index}
                  label={option.label}
                />
              );
            })}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderGroups = (groups) => {
    const maxElementForAccordion = notMobile ? 20 : 8;
    return groups.length > 1 || enumOptions.length > maxElementForAccordion;
  };

  const row = uiSchema ? uiSchema?.['ui:options']?.inline : false;

  return (
    <FormControl error={shouldTriggerError()} required={required}>
      <FormLabel htmlFor={id}>{schema.title}</FormLabel>
      {!isLoading ? (
        renderGroups(checkBoxGroup) && enumOptions.length > 0 ? (
          <>
            {checkBoxWithGroup(checkBoxGroup)}
            <FormHelperText>{schema.description}</FormHelperText>
          </>
        ) : (
          <>
            <FormGroup row={row}>
              {enumOptions.length > 0 ? (
                enumOptions.map((option, index) => {
                  const checked =
                    currentValue && currentValue.indexOf(`${option.id}`) !== -1;
                  const checkbox = (
                    <Checkbox
                      autoFocus={autofocus && index === 0}
                      checked={checked}
                      disabled={
                        disabled ||
                        readonly ||
                        shouldDisableCheckbox(`${option.id}`)
                      }
                      id={`${id}_${index}`}
                      onBlur={_onBlur}
                      onChange={_onChange(option)}
                      onFocus={_onFocus}
                    />
                  );
                  return (
                    <FormControlLabel
                      className={!notMobile ? classes.mobileCheckbox : ''}
                      control={checkbox}
                      key={index}
                      label={option.label}
                    />
                  );
                })
              ) : schema?.['@dynamicData']?.noOptionMessage ? (
                <Typography>
                  {schema['@dynamicData'].noOptionMessage}
                </Typography>
              ) : (
                <Typography>Aucun résultat</Typography>
              )}
            </FormGroup>
            {enumOptions.length > 0 ? (
              <FormHelperText>{schema.description}</FormHelperText>
            ) : null}
          </>
        )
      ) : (
        <FormGroup row={row}>
          <Box className={classes.skeletonBox}>
            <Skeleton
              className={classes.skeletonCheckBox}
              height={20}
              variant='rect'
              width={20}
            />
            <Skeleton
              className={classes.skeletonCheckLabel}
              height={20}
              variant='rect'
              width={250}
            />
          </Box>
          <Box className={classes.skeletonBox}>
            <Skeleton
              className={classes.skeletonCheckBox}
              height={20}
              variant='rect'
              width={20}
            />
            <Skeleton
              className={classes.skeletonCheckLabel}
              height={20}
              variant='rect'
              width={250}
            />
          </Box>
          <Box className={classes.skeletonBox}>
            <Skeleton
              className={classes.skeletonCheckBox}
              height={20}
              variant='rect'
              width={20}
            />
            <Skeleton
              className={classes.skeletonCheckLabel}
              height={20}
              variant='rect'
              width={250}
            />
          </Box>
        </FormGroup>
      )}
    </FormControl>
  );
};

export default CheckboxesWidget;
