import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { KettingProvider, useClient, useResource } from 'react-ketting';
import Breadcrumb from './Components/Breadcrumb';
import Content from './Components/Content';
import BASE_URL from './Utils/Config';
import getKettingClient from './Utils/KettingClient';
import Loading from './Components/Loading';

function NeoBrowser({ flag, flash, authUserService }) {
  const [noApi, setNoApi] = useState(false);
  return (
    <KettingProvider client={getKettingClient(authUserService)}>
      <NoAPI setNoApi={setNoApi} />
      {!noApi && (
        <NeoBrowserContainer
          authUserService={authUserService}
          flag={flag || (() => {})}
          flash={flash || (() => {})}
        />
      )}
    </KettingProvider>
  );
}

NeoBrowser.propTypes = {
  flag: PropTypes.func,
  flash: PropTypes.func.isRequired,
  authUserService: PropTypes.object,
};

function NoAPI({ setNoApi }) {
  const { loading, error } = useResource(`${BASE_URL}/builder`);

  if (loading) return <Loading />;
  if (error) {
    setNoApi(true);
    return <></>;
  }

  return null;
}

NoAPI.propTypes = {
  setNoApi: PropTypes.func.isRequired,
};

function NeoBrowserContainer({ flag, flash, authUserService }) {
  const location = useLocation();
  const history = useHistory();

  const [client] = useState(useClient());
  const [resource, setResource] = useState(null);

  const [url, setUrl] = useState(
    location?.pathname !== '/'
      ? `${BASE_URL}${location.pathname}`
      : `${BASE_URL}/builder`
  );

  useEffect(() => {
    setResource(client.go(url));
  }, [url]);

  const api = {
    go: (newUrl) => {
      setUrl(newUrl);
      history.push(newUrl);
    },
    flag,
    flash,
    authUserService,
  };

  if (!resource) {
    return null;
  }

  // TODO : trouver une méthode moins brutale !
  // pb : quand on édite un client ou projet, la carte correspondante
  // est bien mise à jour, mais pas le breadcrumb (sans le clearCache)
  resource.clearCache();

  return (
    <>
      <Breadcrumb browser={api} resource={resource} />
      <Content browser={api} resource={resource} />
    </>
  );
}

NeoBrowserContainer.propTypes = {
  flag: PropTypes.func,
  flash: PropTypes.func.isRequired,
  authUserService: PropTypes.object,
};

export default NeoBrowser;
