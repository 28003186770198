/* eslint-disable no-constant-condition */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import { Typography, Grid, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import safelyParseJSON from '../../../../Utils/safelyParseJSON';

const FieldJsonLogicEditor = ({ schema, formData, onChange, errorSchema }) => {
  const [data, setData] = useState(safelyParseJSON(formData));
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof formData !== 'string') {
      onChange(JSON.stringify(formData), errorSchema, 2);
    }
  }, []);

  const onChangeInput = (e) => {
    setData(e.jsObject);
    onChange(JSON.stringify(e?.jsObject), errorSchema, 2);
  };

  // TODO: gestion de la langue
  return (
    <Grid container direction='column'>
      <Typography variant='subtitle1'>
        {t('NeoBuilder.Edit.Fields.JsonLogic.Subtitle')}
        <Link href='https://jsonlogic.com/' rel='noopener' target='_blank'>
          JSONLogic
        </Link>
        .
      </Typography>
      <Typography variant='h6'>Logic</Typography>
      <JSONInput
        height={300}
        locale={locale}
        placeholder={data}
        theme='dark_vscode_tribute'
        onChange={(e) => onChangeInput(e)}
      />
      <Typography variant='h6'>Data</Typography>
      <Typography variant='subtitle2'>
        {t('NeoBuilder.Edit.Fields.JsonLogic.DataTooltip')}
      </Typography>
      <JSONInput
        viewOnly
        height={200}
        locale={locale}
        placeholder={schema?.jsonLogicData || {}}
        theme='dark_vscode_tribute'
      />
    </Grid>
  );
};

export default FieldJsonLogicEditor;
