import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Product from './Product';
import withUseCollection from './Hoc/withUseCollection';
import withUseResource from './Hoc/withUseResource';
import AddProduct from './AddProduct';

const SimpleCatalog = ({
  resource,
  resourceState,
  setResourceState,
  collection,
  browser,
  title,
}) => {
  const [products, setProducts] = useState([]);
  const [addAction, setAddAction] = useState(null);

  const add = (product) => {
    const newState = resourceState;
    newState.links.add('item', product.href);
    setResourceState(newState);
  };

  const remove = (res) => {
    const newState = resourceState;
    // get item links not equal to the one to delete
    const links = newState.links
      .getMany('item')
      .filter((link) => link.href !== res.href);

    // delete all item links
    newState.links.delete('item');

    // replace all remaining item links
    links.forEach((link) => {
      newState.links.add('item', link.href);
    });

    setResourceState(newState);
  };

  const edit = (res) => {
    browser.go(res);
  };

  const show = (res) => {
    browser.go(res);
  };

  const api = {
    add,
    remove,
    edit,
    show,
    flag: browser.flag,
    flash: browser.flash,
    authUserService: browser.authUserService,
  };

  useEffect(() => {
    setProducts(
      collection.map((item, index) => (
        <Product catalog={api} key={index} resource={item} />
      ))
    );
  }, [collection]);

  useEffect(() => {
    if (resourceState?.links.has('create')) {
      setAddAction(
        <AddProduct catalog={api} resource={resource.follow('create')} />
      );
    }
  }, [resourceState]);

  return (
    <>
      {title ? (
        <Typography gutterBottom noWrap variant='subtitle1'>
          {title}
        </Typography>
      ) : null}
      <Grid
        container
        alignItems='flex-start'
        direction='row'
        justifyContent='flex-start'
        spacing={4}
      >
        {products}
        {addAction}
      </Grid>
    </>
  );
};

const Catalog = withUseResource(withUseCollection(SimpleCatalog));

SimpleCatalog.propTypes = {
  resource: PropTypes.object.isRequired,
  resourceState: PropTypes.object.isRequired,
  setResourceState: PropTypes.func,
  collection: PropTypes.array.isRequired,
  browser: PropTypes.object,
  title: PropTypes.string,
};

export default Catalog;
