/**
 * CTF: Formulaire Nom/Prénom
 */
export default ({ jsonSchema }) => {
  let flagName = null;

  if (
    jsonSchema?.properties?.nom?.title === 'Nom' &&
    jsonSchema?.properties?.prenom?.title === 'Prénom'
  ) {
    flagName = 'formNameSurname';
  }

  return flagName;
};
