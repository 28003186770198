import React from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import materialDark from 'react-syntax-highlighter/dist/esm/styles/prism/material-dark';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

const tableComponent = ({ children }) => (
  <TableContainer component={Paper}>
    <Table aria-label='a dense table' size='small'>
      {children}
    </Table>
  </TableContainer>
);

const theadComponent = ({ children }) => (
  <TableHead align='center'>{children}</TableHead>
);

const thComponent = ({ children }) => (
  <TableCell align='center'>
    <Typography>
      <strong>{children}</strong>
    </Typography>
  </TableCell>
);

const tbodyComponent = ({ children }) => (
  <TableBody align='center'>{children}</TableBody>
);

const trComponent = ({ children }) => (
  <TableRow align='center'>{children}</TableRow>
);

const tdComponent = ({ children }) => (
  <TableCell align='center'>
    <Typography>{children}</Typography>
  </TableCell>
);

const codeComponent = ({ _node, inline, className, children, ...props }) => {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <SyntaxHighlighter
      // eslint-disable-next-line react/no-children-prop
      children={String(children).replace(/\n$/, '')}
      language={match[1]}
      PreTag='div'
      style={materialDark}
      {...props}
    />
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
};

const components = {
  table: tableComponent,
  thead: theadComponent,
  th: thComponent,
  tbody: tbodyComponent,
  tr: trComponent,
  td: tdComponent,
  code: codeComponent,
};

export default components;
