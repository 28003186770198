/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { useRecoilValue } from 'recoil';
import currentFormAtom from '../../../../../Atoms/currentForm';

import safelyParseJSON from '../../../../../Utils/safelyParseJSON';
import { getOptionsAsync } from '../../../../../Utils/engine/triggerApi';

// const nums = new Set(['number', 'integer']);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
const processValue = (schema, value) => {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema;
  if (value === '') {
    return undefined;
  }
  if (type === 'array' && items) {
    return value.map((element) => `${element}`);
  }
  return `${value}`;

  // if (type === 'boolean') {
  //   return value === 'true';
  // }
  // if (type === 'number') {
  //   return Number(value);
  // }

  // // If type is undefined, but an enum is present, try and infer the type from
  // // the enum values
  // if (schema.enum) {
  //   if (schema.enum.every((x) => typeof x === 'number')) {
  //     return Number(value);
  //   }
  //   if (schema.enum.every((x) => typeof x === 'boolean')) {
  //     return value === 'true';
  //   }
  // }
};

const SelectField = ({
  schema,
  id,
  label,
  formData,
  required,
  disabled,
  readonly,
  autofocus,
  onChange,
  errorSchema,
  onBlur,
  onFocus,
  rawErrors = [],
}) => {
  const currentForm = useRecoilValue(currentFormAtom);
  const [enumOptions, setEnumOptions] = useState([]);
  const parsedFormData = safelyParseJSON(formData);
  const [currentValue, setCurrentvalue] = useState(
    parsedFormData || (!schema?.items ? '' : [])
  );

  useEffect(() => {
    if (
      schema?.['@dynamicData'] &&
      schema['@dynamicData']?.['@paramsAtFetch'] &&
      schema['@dynamicData']?.['@fetched']
    ) {
      setEnumOptions(schema['@dynamicData']['@fetched']);
    }
  }, [currentForm.dynamicData.updatedTimestamp]);

  useEffect(() => {
    getOptionsAsync(schema['@dynamicData']).then((filteredOptions) => {
      setEnumOptions(filteredOptions);
    });
  }, []);

  const _onChange = ({ target: { value } }) => {
    setCurrentvalue(processValue(schema, value));
    onChange(processValue(schema, value), errorSchema, 2);
  };

  const _onBlur = ({ target: { value } }) =>
    onBlur(id, processValue(schema, value));

  const _onFocus = ({ target: { value } }) =>
    onFocus(id, processValue(schema, value));

  return (
    <TextField
      select
      autoFocus={autofocus}
      disabled={disabled || readonly}
      error={rawErrors.length > 0}
      id={id}
      InputLabelProps={{
        shrink: true,
      }}
      label={label || schema.title}
      required={required}
      SelectProps={{
        multiple: !schema?.items ? false : schema?.items,
      }}
      value={currentValue}
      onBlur={_onBlur}
      onChange={_onChange}
      onFocus={_onFocus}
    >
      {enumOptions.map((option, i) => (
        <MenuItem disabled={disabled} key={i} value={`${option.id}`}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

SelectField.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string.isRequired,
    '@dynamicData': PropTypes.object.isRequired,
    description: PropTypes.string,
    items: PropTypes.any,
  }),
  required: PropTypes.bool,
  formData: PropTypes.any,
  errorSchema: PropTypes.any,
};

export default SelectField;
