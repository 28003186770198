import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';

import { useRecoilValue } from 'recoil';
import currentFormAtom from '../../../../../../Atoms/currentForm';

import { getOptionsAsync } from '../../../../../../Utils/engine/triggerApi';
import safelyParseJSON from '../../../../../../Utils/safelyParseJSON';

const MultipleAutocomplete = ({
  schema,
  required,
  formData,
  onChange,
  errorSchema,
  dynamicLoading,
}) => {
  const currentForm = useRecoilValue(currentFormAtom);
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentvalue] = useState(
    safelyParseJSON(formData) ?? []
  );
  const [options, setOptions] = useState([]);

  const onChangeInput = (e, newInputValue) => {
    setData(newInputValue);
  };

  useEffect(() => {
    if (
      schema?.['@dynamicData'] &&
      schema['@dynamicData']?.['@paramsAtFetch'] &&
      schema['@dynamicData']?.['@fetched']
    ) {
      setOptions(schema['@dynamicData']['@fetched']);
    }
  }, [currentForm.dynamicData.updatedTimestamp]);

  // const [inputValue, setInputValue] = React.useState('');
  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      setOptions([]);
      getOptionsAsync(schema['@dynamicData'], text, currentValue).then(
        callback
      );
    }, 500),
    []
  );

  useEffect(() => {
    setLoading(true);
    getOptionsDelayed(`${data}`, (filteredOptions) => {
      setOptions(filteredOptions);
      setLoading(false);
    });
  }, [data, getOptionsDelayed]);

  return (
    <Autocomplete
      disableCloseOnSelect
      multiple
      // disable filtering on client side
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label || option}
      getOptionSelected={(option) => option.id || option}
      loading={options.length === 0}
      loadingText={dynamicLoading(
        schema['@dynamicData'],
        data,
        options,
        loading
      )}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label={schema.title} required={required} />
      )}
      onChange={(_event, newValue) => {
        console.log(
          'onChange',
          newValue.map((element) => element.id)
        );
        setCurrentvalue(newValue.map((element) => `${element.id}`));
        onChange(
          newValue.map((element) => `${element.id}`),
          errorSchema,
          2
        );
      }}
      onInputChange={(e, newInputValue) => onChangeInput(e, newInputValue)}
    />
  );
};

MultipleAutocomplete.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string.isRequired,
    '@dynamicData': PropTypes.object.isRequired,
    description: PropTypes.string,
  }),
  required: PropTypes.bool,
  formData: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  errorSchema: PropTypes.any,
  dynamicLoading: PropTypes.func,
};

export default MultipleAutocomplete;
