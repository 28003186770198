import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import axios from 'axios';
import NeoFormUse from '../NeoFormUse';
import NeoFormSkeleton from '../NeoFormSkeleton';

const NeoFormHosted = ({
  callback,
  uri,
  id,
  handleSubmit,
  formData,
  submitName,
  handleChange = null,
  handleValidation = null,
}) => {
  const conditionalProps = {
    ...(id && { id }),
    ...(handleChange && { handleChange }),
    ...(handleValidation && {
      handleValidation,
    }),
  };

  const [data, setData] = useState({});

  useEffect(() => {
    if (uri) {
      const ApiClient = axios.create({
        maxContentLength: 20971520,
        maxBodyLength: 20971520,
      });

      ApiClient.request({
        url: uri,
      })
        .then(async (response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [uri]);

  const addFormInfoInData = (result, schema) => {
    // eslint-disable-next-line no-param-reassign
    let ResultWithMeta = result;
    ResultWithMeta = {
      ...result,
      '@uri': uri,
      '@id': data?.id,
      '@version': data?.version,
      '@role': data?.role,
    };
    handleSubmit(ResultWithMeta, schema);
  };

  return data?.jsonSchema ? (
    <NeoFormUse
      customSubmit={
        <Button color='primary' type='submit' variant='contained'>
          {submitName}
        </Button>
      }
      extensions={['rating', 'literary', 'deepDependencies', 'dynamicData']}
      formAccess={data?.formAccess || { read: true, write: true }}
      formDataReceived={formData}
      handleSubmit={addFormInfoInData}
      JsonSchema={data?.jsonSchema || {}}
      muiTheme={data?.muiTheme || {}}
      UiSchema={data?.uiSchema || {}}
      {...conditionalProps}
      callback={callback}
    />
  ) : (
    <NeoFormSkeleton />
  );
};

NeoFormHosted.defaultProps = {
  callback: () => {},
  formData: {},
  submitName: 'SUBMIT',
  handleChange: null,
  handleValidation: null,
  id: '',
};

NeoFormHosted.propTypes = {
  callback: PropTypes.func,
  uri: PropTypes.string.isRequired,
  id: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  handleValidation: PropTypes.func,
  formData: PropTypes.object,
  submitName: PropTypes.string,
};

export default NeoFormHosted;
