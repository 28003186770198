/* eslint-disable no-constant-condition */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import safelyParseJSON from '../../../../Utils/safelyParseJSON';

const FieldJsonEditor = ({ schema, formData, onChange, errorSchema }) => {
  const parsedFormData = safelyParseJSON(formData);
  const [data, setData] = useState(
    typeof parsedFormData === 'string' ? {} : parsedFormData
  );

  const onChangeInput = (e) => {
    setData(e.jsObject);
    onChange(JSON.stringify(e?.jsObject), errorSchema, 2);
  };

  // TODO: gestion de la langue
  return (
    <Grid container direction='column'>
      <Typography color='primary' variant='subtitle1'>
        {schema.title}
      </Typography>
      <JSONInput
        height={300}
        locale={locale}
        placeholder={data}
        theme='dark_vscode_tribute'
        waitAfterKeyPress={2000}
        width='100%'
        onKeyPressUpdate
        onChange={(e) => onChangeInput(e)}
      />
    </Grid>
  );
};

export default FieldJsonEditor;
