/**
 * CTF: Ajout d'un rôle dans un formulaire
 */
export default ({ formBuilder }) => {
  let flagName = null;

  const roles = formBuilder?.blueprint?.roles;
  const permissions = formBuilder?.blueprint?.permissions;

  if (roles && permissions && formBuilder?.blueprint?.elements?.length === 3) {
    if (
      roles.includes('Default') &&
      !permissions?.Default?.read &&
      !permissions?.Default?.write &&
      roles.includes('Conseiller') &&
      permissions?.Conseiller?.read === true &&
      permissions?.Conseiller?.write === true &&
      roles.includes('Hotline') &&
      permissions?.Hotline?.read === true &&
      !permissions?.Hotline?.write
    ) {
      let ok = true;
      const { elements } = formBuilder.blueprint;

      elements.forEach((element) => {
        const editAction = element?.blueprint?.actions?.find(
          (x) => x.id === 'edit'
        );

        if (editAction?.formData?.tabs?.data?.id) {
          const access = editAction?.formData?.tabs?.access;

          switch (editAction.formData.tabs.data.id) {
            case 'animal':
            case 'sport':
              if (access?.accessActivation === 'Yes') {
                ok = ok && false;
              }
              break;

            case 'couleur':
              if (
                access?.accessActivation === 'Yes' &&
                !access?.Default?.read &&
                !access?.Default?.write &&
                access?.Conseiller?.read === true &&
                !access?.Conseiller?.write &&
                !access?.Hotline?.read &&
                !access?.Hotline?.write
              ) {
                ok = ok && true;
              } else {
                ok = ok && false;
              }
              break;

            default:
              break;
          }
        }
      });

      if (ok) {
        flagName = 'useRoleOnForm';
      }
    }
  }

  return flagName;
};
