import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ErrorIcon from '@mui/icons-material/Error';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';

const ErrorListFr = ({ errors }) => (
  <Paper elevation={2}>
    <Box mb={2} p={2}>
      <Typography variant='h6'>Erreurs</Typography>
      <List dense>
        {errors.map((error, i) => (
          <ListItem key={i}>
            <ListItemIcon>
              <ErrorIcon color='error' />
            </ListItemIcon>
            <ListItemText primary={error.stack} />
          </ListItem>
        ))}
      </List>
    </Box>
  </Paper>
);

ErrorListFr.propTypes = {
  errors: PropTypes.object,
};

export default ErrorListFr;
