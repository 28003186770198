import { cloneDeep, isEqual } from 'lodash';

const renameAction = (
  localMetaSpec,
  setLocalMetaSpec,
  savedFormName,
  unsavedChange,
  setUnsavedChange
) => {
  const onChangeFormName = (event) => {
    if (!isEqual(savedFormName, event.target.value)) {
      if (!unsavedChange) {
        setUnsavedChange(true);
      }
    } else {
      setUnsavedChange(false);
    }
    const newMetaSpec = cloneDeep(localMetaSpec);
    newMetaSpec.formName = event.target.value;
    setLocalMetaSpec(newMetaSpec);
  }; // fin onChangeFormName

  return { onChangeFormName };
};

export default renameAction;
