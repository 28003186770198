import { cloneDeep, get, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import findYByX from '../../../../../Utils/objectManipulation/findYByX';
import findPathById from '../../../../../Utils/objectManipulation/findPathById';

const dragAndDropAction = (editElement, localMetaSpec, setLocalMetaSpec) => {
  const copyDnd = (item, global, droppableDestination, droppableSource) => {
    const destinationPath = findPathById(
      global,
      droppableDestination.droppableId
    );
    const sourcePath = findPathById(global, droppableSource.droppableId);
    const destElementsPath = destinationPath;
    const sourceElementsPath = sourcePath;
    destElementsPath[destinationPath.length - 1] = 'elements';
    sourceElementsPath[sourcePath.length - 1] = 'elements';

    const itemDeepCloned = cloneDeep(item);

    const destElements = get(global, destElementsPath);
    const sourceElements = get(global, sourceElementsPath);
    destinationPath.splice(-1, 1);
    const destination = get(global, destinationPath);

    if (destElements && sourceElements) {
      destElements.splice(droppableDestination.index, 0, {
        ...itemDeepCloned,
        blueprint: {
          ...itemDeepCloned.blueprint,
          id: uuidv4(),
        },
      });
      if (droppableSource.droppableId !== 'fieldsList') {
        sourceElements.splice(droppableSource.index, 1);
      }
      editElement(destElements[droppableDestination.index], destination, true);
      // eslint-disable-next-line no-param-reassign
      localMetaSpec.updateTimestamp = Date.now();
    }
    return global;
  }; // fin copyDnd

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // Si on lâche notre élément en dehors d'une cible reconnue
    if (!destination) return;

    // Si on lâche notre élément à l'emplacement qu'il occupait au départ
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newMetaSpec = cloneDeep(localMetaSpec);
    const tmpMenuStart = findYByX(newMetaSpec, 'id', source.droppableId)?.[0];
    tmpMenuStart.path.splice(-1, 1);
    const menuStart = get(newMetaSpec, tmpMenuStart.path);
    const tmpMenuDestination = findYByX(
      newMetaSpec,
      'id',
      destination.droppableId
    )?.[0];
    tmpMenuDestination.path.splice(-1, 1);
    const menuDestination = get(newMetaSpec, tmpMenuDestination.path);

    if (menuStart && menuStart.elements.length > 0) {
      if (isEqual(menuStart, menuDestination)) {
        const newElementIds = Array.from(menuStart.elements);
        newElementIds.splice(source.index, 1);
        const find = menuStart.elements.find((element) => {
          if (element) {
            return element.blueprint.id === draggableId;
          }
          return 0;
        });
        newElementIds.splice(destination.index, 0, find);
        menuStart.elements = newElementIds;
        // newMetaSpec.menus.formBuilder = menuStart;
        newMetaSpec.updateTimestamp = Date.now();
        setLocalMetaSpec(newMetaSpec);
      } else {
        const find = menuStart.elements.find(
          (element) => element.blueprint.id === draggableId
        );
        setLocalMetaSpec((state) =>
          cloneDeep(copyDnd(find, state, destination, source))
        );
      }
    }
  }; // fin onDragEnd

  return { onDragEnd };
};

export default dragAndDropAction;
