import React, { createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { cloneDeep, isEqual } from 'lodash';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@mui/styles';
import snackbarAtom from '../../../Atoms/snackbarAtom';
import PopupDialog from '../../PopupDialog';
import NeoFormUse from '../NeoFormUse';

const NeoFormAccessStyle = makeStyles(() => ({
  'container-roles-header': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 16,
  },
  saveButton: {
    marginRight: 32,
    marginLeft: 16,
  },
}));

const NeoFormAccess = ({
  handleSaveBuilder,
  localMetaSpecParent,
  rolesReceived,
  unsaved,
}) => {
  const classes = NeoFormAccessStyle();

  const { localMetaSpec, setLocalMetaSpec } = localMetaSpecParent;
  const { unsavedChange, setUnsavedChange, savedBuilderSchema } = unsaved;
  const [formDataRoles, setFormDataRoles] = useState({ roles: rolesReceived });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});
  const [jsonSchemaPermission, setjsonSchemaPermission] = useState({
    type: 'object',
    properties: {},
  });
  const [uiSchemaPermission, setuiSchemaPermission] = useState({
    'ui:order': [],
    attention: { 'ui:field': 'NeoAlertMessage' },
  });
  const setAtomSnackbar = useSetRecoilState(snackbarAtom);
  const { t } = useTranslation();

  const fillPermissionsForm = () => {
    const newUiSchemaPermission = {
      'ui:order': [],
      attention: { 'ui:field': 'NeoAlertMessage' },
    };
    const newPermissionsRoles = {
      type: 'object',
      properties: {},
    };

    formDataRoles?.roles?.map((element) => {
      newPermissionsRoles.properties = {
        ...newPermissionsRoles.properties,
        [element]: {
          type: 'object',
          title: element,
          properties: {
            read: {
              type: 'boolean',
              title: t('NeoBuilder.Access.ReadPermission'),
            },
            write: {
              type: 'boolean',
              title: t('NeoBuilder.Access.WritePermission'),
            },
          },
        },
      };

      newUiSchemaPermission['ui:order'] = [
        ...newUiSchemaPermission['ui:order'],
        element,
      ];

      return null;
    });

    newPermissionsRoles.properties = {
      ...newPermissionsRoles.properties,
      attention: {
        type: 'string',
        title: 'warning',
        description:
          "***Attention*** l'actualisation des droits n'enregistre pas les modifications en cours",
      },
    };

    newUiSchemaPermission['ui:order'] = [
      ...newUiSchemaPermission['ui:order'],
      'attention',
    ];

    setjsonSchemaPermission(newPermissionsRoles);
    setuiSchemaPermission(newUiSchemaPermission);
  };

  useEffect(() => {
    fillPermissionsForm();
  }, [formDataRoles?.roles?.length, rolesReceived]);

  const openDialogRolesList = () => {
    const submitFormRef = createRef();

    const submitRolesList = (result) => {
      setAtomSnackbar((prevAtomSnackbar) => ({
        ...prevAtomSnackbar,
        open: true,
        text: t('Snackbar.EditAccessRoles'),
        severity: 'success',
      }));
      setDialogOpen(false);

      formDataRoles.roles = formDataRoles?.roles.concat(
        result?.roles.filter((item) => formDataRoles?.roles.indexOf(item) < 0)
      );
      setFormDataRoles(formDataRoles);

      // /!\ "copie" par référence, donc modification de la source
      const newBuilderSchema = localMetaSpec.menus.formBuilder;
      newBuilderSchema.roles = formDataRoles.roles;
      localMetaSpec.updateTimestamp = Date.now();
      setLocalMetaSpec(cloneDeep(localMetaSpec));

      fillPermissionsForm();
    };

    setDialogConfig({
      title: '',
      content: (
        <NeoFormUse
          builder
          customSubmit={
            <Button
              ref={submitFormRef}
              style={{ display: 'none' }}
              type='submit'
            >
              submitForm
            </Button>
          }
          extensions={[]}
          formDataReceived={{}}
          handleSubmit={submitRolesList}
          JsonSchema={{
            type: 'object',
            properties: {
              roles: {
                title: t('NeoBuilder.Access.AddRoles'),
                type: 'array',
                minItems: 1,
                uniqueItems: true,
                items: {
                  type: 'string',
                  title: t('NeoBuilder.Access.Value'),
                },
              },
            },
          }}
          UiSchema={{
            roles: {
              'ui:options': {
                orderable: false,
              },
            },
          }}
        />
      ),
      actions: (
        <>
          <Button
            color='primary'
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            {t('Global.Cancel')}
          </Button>
          <Button
            color='primary'
            onClick={() => {
              submitFormRef.current.click();
            }}
          >
            {t('Global.Submit')}
          </Button>
        </>
      ),
    });
    setDialogOpen(true);
  };

  const submitPermissions = (result, _Schema) => {
    // /!\ "copie" par référence, donc modification de la source
    const newBuilderSchema = localMetaSpec.menus.formBuilder;
    newBuilderSchema.permissions = result;
    localMetaSpec.updateTimestamp = Date.now();
    setLocalMetaSpec(cloneDeep(localMetaSpec));

    if (!isEqual(savedBuilderSchema.permissions, result)) {
      if (!unsavedChange) {
        setUnsavedChange(true);
      }
    } else if (unsavedChange) setUnsavedChange(false);
  };

  return (
    <>
      <Grid container className={classes['container-roles-header']}>
        <Button
          color='primary'
          type='submit'
          variant='contained'
          onClick={openDialogRolesList}
        >
          {t('NeoBuilder.Access.AddRole')}
        </Button>
        <Button
          className={classes.saveButton}
          color='primary'
          disabled={!unsavedChange}
          startIcon={<SaveIcon />}
          variant='contained'
          onClick={() => handleSaveBuilder(localMetaSpec)}
        >
          {t('NeoBuilder.Edit.Save')}
        </Button>
      </Grid>
      <NeoFormUse
        builder
        customSubmit={<></>}
        formDataReceived={localMetaSpec.menus.formBuilder.permissions}
        handleChange={(
          result,
          _jsonSchema,
          _setJsonSchema,
          _uiSchema,
          _setUiSchema,
          _setFormData,
          oldFormdata
        ) => {
          if (typeof oldFormdata !== 'undefined') {
            submitPermissions(result);
          }
        }}
        handleSubmit={submitPermissions}
        JsonSchema={jsonSchemaPermission}
        UiSchema={uiSchemaPermission}
      />
      <PopupDialog
        config={dialogConfig}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

NeoFormAccess.propTypes = {
  localMetaSpecParent: PropTypes.shape({
    localMetaSpec: PropTypes.object.isRequired,
    setLocalMetaSpec: PropTypes.func.isRequired,
  }).isRequired,
  unsaved: PropTypes.shape({
    unsavedChange: PropTypes.bool.isRequired,
    setUnsavedChange: PropTypes.func.isRequired,
    savedBuilderSchema: PropTypes.object.isRequired,
  }).isRequired,
  rolesReceived: PropTypes.array,
  handleSaveBuilder: PropTypes.func.isRequired,
};

export default NeoFormAccess;
