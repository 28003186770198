import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import traverseObject from '../../../../../Utils/objectManipulation/traverseObject';

const copyAction = (
  editElement,
  localMetaSpec,
  setLocalMetaSpec,
  setAtomSnackbar
) => {
  const { t } = useTranslation();

  const copyElement = (field, menu) => {
    if (field?.blueprint?.actions) {
      const fieldDeepCloned = cloneDeep(field);
      const fieldData = fieldDeepCloned.blueprint.actions.find(
        (x) => x.id === 'edit'
      )?.formData;
      const uuid = uuidv4();
      // update internal ID of the field
      if (fieldData?.tabs?.data?.id) {
        fieldData.tabs.data = {
          ...fieldData.tabs.data,
          id: uuid,
        };
        menu.elements.splice(menu.elements.length, 0, {
          ...fieldDeepCloned,
          blueprint: {
            ...fieldDeepCloned.blueprint,
            id: uuid,
          },
        });
      } else {
        menu.elements.splice(menu.elements.length, 0, {
          ...fieldDeepCloned,
          blueprint: {
            ...fieldDeepCloned.blueprint,
            id: uuid,
          },
        });
      }
      // update internal ID of each child element of the field, recursively, if any
      if (fieldDeepCloned?.blueprint?.elements?.length > 0) {
        traverseObject(fieldDeepCloned.blueprint.elements, (k, v) => {
          if (k === 'blueprint' && v?.id) {
            // eslint-disable-next-line no-param-reassign
            v.id = uuidv4();
          }
        });
      }
      editElement(menu.elements.slice(-1)[0], menu, true);
      const newMetaSpec = cloneDeep(localMetaSpec);
      newMetaSpec.updateTimestamp = Date.now();
      setLocalMetaSpec(newMetaSpec);
      setAtomSnackbar((prevAtomSnackbar) => ({
        ...prevAtomSnackbar,
        open: true,
        text: t('Snackbar.CopyField'),
        severity: 'success',
      }));
    }
  }; // fin copyElement

  return copyElement;
};

export default copyAction;
