//
import { Client } from 'ketting';
import BASE_URL from './Config';

const getKettingClient = (utils) => {
  const KettingClient = new Client(BASE_URL);

  // Ketting middleware for Authorization Header
  KettingClient.use((request, next) => {
    const getBearerToken = () => `Bearer ${utils.getToken()}`;
    request.headers.set('Authorization', getBearerToken());
    return next(request);
  });

  return KettingClient;
};

export default getKettingClient;
