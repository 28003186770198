/* eslint-disable no-constant-condition */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
// Test FormBuilder
import React, { useEffect, useState, useRef } from 'react';
import { cloneDeep } from 'lodash';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useRecoilValue } from 'recoil';
import currentFormAtom from '../../../../../Atoms/currentForm';

const FieldStepperStyles = makeStyles((theme) => ({
  MobileTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    pl: 2,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  MobileContent: {
    padding: theme.spacing(2),
  },
  MobileStepper: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
}));

const FieldStepper = (props) => {
  const { schema, uiSchema, registry, formData, onChange, errorSchema } = props;
  const { SchemaField } = registry.fields;
  const currentForm = useRecoilValue(currentFormAtom);
  const headerRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);

  const theme = useTheme();
  const classes = FieldStepperStyles(theme);
  const notMobile = useMediaQuery(theme.breakpoints.up('sm'));

  let steps;

  // console.log(schema);
  // console.log(uiSchema);

  useEffect(() => {}, [
    currentForm.deepDependencies.updatedTimestamp,
    currentForm.dynamicData.updatedTimestamp,
  ]);

  useEffect(() => {
    const cloneFormData = cloneDeep(formData);
    if (cloneFormData.length > 0) {
      cloneFormData['@currentStep'] = activeStep;
    }
    onChange(cloneFormData, errorSchema);
  }, [activeStep]);

  const totalSteps = () => steps.length;

  // const isLastStep = () => activeStep === totalSteps() - 1;

  // const allStepsCompleted = () => completedSteps() === totalSteps();

  const scrollToHeader = () =>
    headerRef.current.scrollIntoView({ behavior: 'instant' });

  const handleNext = () => {
    scrollToHeader();
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    scrollToHeader();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    scrollToHeader();
    setActiveStep(step);
  };

  if (schema && schema.properties) {
    steps = Object.entries(schema.properties).map((el, index) => {
      let id;
      let element;
      [id, element] = el;

      const { title } = element;

      const clonedElement = cloneDeep(element);
      clonedElement.title = '';

      const onChangeFieldStepper = (e) => {
        const cloneFormData = cloneDeep(formData);
        cloneFormData[id] = e;
        onChange(cloneFormData, errorSchema);
      };

      return {
        name: title,
        component: (
          <div key={index} name={title}>
            <SchemaField
              {...props}
              formData={(formData || {})[id]}
              registry={registry}
              schema={clonedElement}
              uiSchema={uiSchema[id]}
              validate={() => {}}
              onChange={onChangeFieldStepper}
            />
          </div>
        ),
      };
    });
  }

  let previousNext = false;
  if (
    uiSchema?.['ui:options']?.previousNextbuttons &&
    uiSchema?.['ui:options']?.previousTitle &&
    uiSchema?.['ui:options']?.nextTitle
  ) {
    previousNext = {
      previousTitle: uiSchema['ui:options'].previousTitle,
      nextTitle: uiSchema['ui:options'].nextTitle,
    };
  }

  const renderField = () => {
    if (steps && steps.length > 0) {
      // return <SimpleTabs previousNext={previousNext} tabs={tabs} />;
      return (
        <>
          {notMobile ? (
            <>
              <Box sx={{ width: '100%' }}>
                <Stepper nonLinear activeStep={activeStep} ref={headerRef}>
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepButton color='inherit' onClick={handleStep(index)}>
                        {step.name}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>

                <>
                  {steps[activeStep].component}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color='inherit'
                      disabled={activeStep === 0}
                      sx={{ mr: 1 }}
                      onClick={handleBack}
                    >
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      {previousNext?.previousTitle || 'Back'}
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                      disabled={totalSteps() === activeStep + 1}
                      sx={{ mr: 1 }}
                      onClick={handleNext}
                    >
                      {previousNext?.nextTitle || 'Next'}
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  </Box>
                </>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ width: '100%', flexGrow: 1 }}>
                <Paper
                  square
                  className={classes.MobileTitle}
                  elevation={0}
                  ref={headerRef}
                >
                  <Typography variant='h5'>{steps[activeStep].name}</Typography>
                </Paper>
                <Box className={classes.MobileContent} overflow='auto'>
                  {steps[activeStep].component}
                </Box>
                <MobileStepper
                  activeStep={activeStep}
                  backButton={
                    <Button
                      disabled={activeStep === 0}
                      size='small'
                      onClick={handleBack}
                    >
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      {previousNext?.previousTitle || 'Back'}
                    </Button>
                  }
                  nextButton={
                    <Button
                      disabled={activeStep === totalSteps() - 1}
                      size='small'
                      onClick={handleNext}
                    >
                      {previousNext?.nextTitle || 'Next'}
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  position='static'
                  steps={totalSteps()}
                  variant='text'
                />
              </Box>
            </>
          )}
        </>
      );
    }
    return null;
  };

  return renderField();
};

export default FieldStepper;
