import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import NeoFormUse from '../NeoFormUse';
import ApiClient from '../../NeoBrowser/Utils/ApiClient';
import NeoFormSkeleton from '../NeoFormSkeleton';

const NeoFormLocal = ({
  formId,
  BuilderSchema,
  userRole,
  token,
  handleSubmit,
  formData,
  submitName,
}) => {
  const [data, setData] = useState({});

  useEffect(() => {
    ApiClient.request({
      method: 'post',
      url: `/form/preview`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        builderSchema: BuilderSchema,
        role: userRole,
        ...(formId && { formId }),
      },
    })
      .then(async (response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [BuilderSchema, userRole, token]);

  return data?.jsonSchema ? (
    <NeoFormUse
      customSubmit={
        <Button color='primary' type='submit' variant='contained'>
          {submitName}
        </Button>
      }
      extensions={['rating', 'literary', 'deepDependencies', 'dynamicData']}
      formAccess={data?.formAccess || { read: true, write: true }}
      formDataReceived={formData}
      handleSubmit={handleSubmit}
      JsonSchema={data?.jsonSchema || {}}
      muiTheme={data?.muiTheme || {}}
      UiSchema={data?.uiSchema || {}}
    />
  ) : (
    <NeoFormSkeleton />
  );
};

NeoFormLocal.defaultProps = {
  formData: {},
  submitName: 'SUBMIT',
};

NeoFormLocal.propTypes = {
  formId: PropTypes.string,
  BuilderSchema: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object,
  submitName: PropTypes.string,
};

export default NeoFormLocal;
