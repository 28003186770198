//
import axios from 'axios';
import BASE_URL from './Config';

const ApiClient = axios.create({
  baseURL: BASE_URL,
  // 20MB : 1024 * 1024 * 20 => 20971520
  maxContentLength: 20971520,
  maxBodyLength: 20971520,
});

export default ApiClient;
