import React from 'react';
import { useResource } from 'react-ketting';
import Loading from '../Loading';
import Error from '../Error';

const withUseResource =
  (WrappedComponent, type) =>
  // eslint-disable-next-line react/prop-types
  ({ resource, ...props }) => {
    const {
      loading,
      error,
      data,
      setData,
      submit,
      resourceState,
      setResourceState,
    } = useResource(resource);

    if (loading) return <Loading type={type} />;
    if (error) return <Error msg={error.message} type={type} />;

    // eslint-disable-next-line react/prop-types
    const { children } = props;

    const newProps = {
      resource,
      resourceData: data,
      setResourceData: setData,
      submitResourceData: submit,
      resourceState,
      setResourceState,
    };

    return (
      <WrappedComponent {...newProps} {...props}>
        {children}
      </WrappedComponent>
    );
  };

export default withUseResource;
