import jsonLogic from 'json-logic-js';
import findYByX from '../../../objectManipulation/findYByX';
import findByKeyValue from '../../../objectManipulation/findByKeyValue';
import safelyParseJSON from '../../../safelyParseJSON';

const averageOnAnswers = (array) => {
  const cleanArray = array.filter(
    (element) => element !== false && element !== ''
  );
  if (cleanArray.length > 0) {
    let sum = 0;
    cleanArray.map((value) => {
      sum += +value;
      return value;
    });
    return sum / cleanArray.length;
  }
  return 0;
};

const countKeyInArray = (array, key) => {
  const cleanArray = array.filter((element) => element === key);
  return cleanArray.length;
};

const extensionLiterary = (formdata, jsonSchema, recursiveAssignFormData) => {
  jsonLogic.add_operation('averageOnAnswers', averageOnAnswers);
  jsonLogic.add_operation('countKeyInArray', countKeyInArray);

  const rateJsonLogics = findYByX(
    jsonSchema,
    'idJsonLogicRateFormula',
    'jsonLogicRateFormula'
  );

  const copyRateJsonLogics = rateJsonLogics;

  copyRateJsonLogics.sort(
    (a, b) =>
      b.content['@source'].split('.').length -
      a.content['@source'].split('.').length
  );

  Promise.all(
    copyRateJsonLogics.map(async (rateJsonLogic, index) => {
      Object.keys(rateJsonLogic?.content?.jsonLogicRateData).forEach((key) => {
        const result = findByKeyValue(formdata, key);
        if (result.length === 1) {
          if (typeof result[0] === 'object') {
            copyRateJsonLogics[index].content.jsonLogicRateData = {
              ...rateJsonLogic?.content.jsonLogicRateData,
              [key]:
                '@JsonLogic_Rating_Result' in result[0]
                  ? result[0]['@JsonLogic_Rating_Result']
                  : false,
            };
          } else {
            copyRateJsonLogics[index].content.jsonLogicRateData = {
              ...rateJsonLogic?.content.jsonLogicRateData,
              [key]: result[0],
            };
          }
        }
        return null;
      });

      const parsedFormula = safelyParseJSON(
        copyRateJsonLogics[index].content.jsonLogicRateFormula
      );

      await recursiveAssignFormData(
        formdata,
        rateJsonLogic?.content.rating,
        null,
        '@JsonLogic_Rating_Result',
        jsonLogic.apply(
          parsedFormula,
          copyRateJsonLogics[index].content.jsonLogicRateData
        )
      );
      return null;
    })
  );
};

export default extensionLiterary;
