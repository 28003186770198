/**
 * CTF: Ajout d'un rôle dans un formulaire
 */
export default ({ formBuilder }) => {
  let flagName = null;

  const roles = formBuilder?.blueprint?.roles;
  const permissions = formBuilder?.blueprint?.permissions;

  if (roles && permissions && formBuilder?.blueprint?.elements?.length === 0) {
    if (
      roles.includes('Default') &&
      !permissions?.Default?.read &&
      !permissions?.Default?.write &&
      roles.includes('Conseiller') &&
      permissions?.Conseiller?.read === true &&
      permissions?.Conseiller?.write === true &&
      roles.includes('Hotline') &&
      permissions?.Hotline?.read === true &&
      !permissions?.Hotline?.write
    ) {
      flagName = 'addRoleToForm';
    }
  }

  return flagName;
};
