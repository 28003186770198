/**
 * CTF: Formulaire Dépendance simple
 *
 * Dans le cadre de ce challenge, le jsonSchema devrait ressembler à quelque chose comme ça:
 *
 * {
 *   type: "object",
 *   required: [
 *   ],
 *   properties: {
 *     couleur_voiture: {
 *       type: "string",
 *       "@source": "couleur_voiture",
 *       title: "De quelle couleur est votre voiture ?",
 *     },
 *     voiture: {
 *       type: "boolean",
 *       "@source": "voiture",
 *       title: "Avez-vous une voiture ?",
 *       enumNames: [
 *         "Oui",
 *         "Non",
 *       ],
 *     },
 *   },
 *   dependencies: {
 *     voiture: {
 *       oneOf: [
 *         {
 *           properties: {
 *             voiture: {
 *               enum: [
 *                 true,
 *               ],
 *             },
 *             couleur_voiture: {
 *               type: "string",
 *               "@source": "couleur_voiture",
 *               title: "De quelle couleur est votre voiture ?",
 *             },
 *           },
 *         },
 *         {
 *           properties: {
 *             voiture: {
 *               enum: [
 *                 false,
 *               ],
 *             },
 *           },
 *         },
 *       ],
 *     },
 *   },
 * }
 */
export default ({ jsonSchema }) => {
  let flagName = null;

  if (jsonSchema?.dependencies?.voiture?.oneOf) {
    const dependencies = jsonSchema.dependencies.voiture.oneOf;

    dependencies.forEach((dependency) => {
      if (dependency?.properties?.couleur_voiture) {
        const choice = dependency?.properties?.voiture?.enum;

        if (choice && choice.length > 0 && choice[0] === true) {
          flagName = 'formDependencie';
        }
      }
    });
  }

  return flagName;
};
