import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import CloseIcon from '@mui/icons-material/Close';
import SpeedDialAction from '@mui/material/SpeedDialAction';

const useStyles = makeStyles((theme) => ({
  backDrop: {
    zIndex: 10,
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(8),
  },
  input: {
    display: 'none',
  },
}));

const SpeedDialTooltipOpen = ({ icon, actions }) => {
  const classes = useStyles();
  const fileContainer = React.createRef();
  const [open, setOpen] = React.useState(false);

  let handleFileChosen = () => {};

  const onClickEffect = (action) => {
    setOpen(false);
    if (action.onClick) action.onClick();
  };

  const getFileEffect = () => {
    setOpen(false);
    fileContainer.current.click();
  };

  return (
    <>
      <Backdrop className={classes.backDrop} open={open} />
      <SpeedDial
        ariaLabel='FormBuilder Actions'
        className={classes.speedDial}
        // icon={<SpeedDialIcon icon={icon} openIcon={<CloseIcon />} />}
        // icon={<SpeedDialIcon />}
        icon={
          (icon && <SpeedDialIcon icon={icon} openIcon={<CloseIcon />} />) || (
            <SpeedDialIcon />
          )
        }
        open={open}
        // openIcon={<SpeedDialIcon />}
        // onClose={handleClose}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {actions.map((action) => {
          if (action.getFile) {
            let fileReader;

            const handleFileRead = () => {
              const content = fileReader.result;
              action.getFile(content);
            };

            handleFileChosen = (file) => {
              fileReader = new FileReader();
              fileReader.onloadend = handleFileRead;
              fileReader.readAsText(file);
            };

            return (
              <SpeedDialAction
                tooltipOpen
                icon={action.icon}
                key={action.name}
                tooltipTitle={action.name}
                onClick={() => getFileEffect()}
              />
            );
          }
          return (
            <SpeedDialAction
              tooltipOpen
              icon={action.icon}
              key={action.name}
              tooltipTitle={action.name}
              onClick={() => onClickEffect(action)}
            />
          );
        })}
      </SpeedDial>
      <input
        className={classes.input}
        ref={fileContainer}
        type='file'
        onChange={(e) => handleFileChosen(e.target.files[0])}
      />
    </>
  );
};

SpeedDialTooltipOpen.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
  icon: PropTypes.node,
};

export default SpeedDialTooltipOpen;
