import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import NeoFormHostedStyle from '../NeoFormHosted/NeoFormHostedStyle';

const NeoFormSkeleton = () => {
  const classes = NeoFormHostedStyle();

  return (
    <>
      <Skeleton
        className={classes.skeletonTitle}
        height={20}
        variant='rect'
        width={250}
      />
      <Skeleton className={classes.skeletonField} height={40} variant='rect' />
      <Skeleton
        className={classes.skeletonTitle}
        height={20}
        variant='rect'
        width={250}
      />
      <Skeleton className={classes.skeletonField} height={40} variant='rect' />
      <Skeleton
        className={classes.skeletonTitle}
        height={20}
        variant='rect'
        width={250}
      />
      <Skeleton className={classes.skeletonField} height={40} variant='rect' />
      <Skeleton
        className={classes.skeletonButton}
        height={45}
        variant='rect'
        width={180}
      />
    </>
  );
};

export default NeoFormSkeleton;
