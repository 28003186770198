/**
 * CTF: Création d'un client
 */
export default ({ createResponse }) => {
  let flagName = null;

  const selfLink = createResponse?.data?._links?.self;

  if (
    selfLink &&
    selfLink?.href?.includes('/clients/') &&
    !selfLink?.href?.includes('/projects/')
  ) {
    flagName = 'createClient';
  }

  return flagName;
};
