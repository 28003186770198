import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Droppable } from 'react-beautiful-dnd';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import findPathById from '../../../Utils/objectManipulation/findPathById';
import Element from '../../Element';
import ActionsGroup from '../../ActionsGroup';

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgrey' : '',
  height: '100%',
});

const Builder = ({
  menu,
  elements,
  classes,
  actions,
  builderZoom,
  localMetaSpec,
  setBuilderZoom,
}) => {
  const { t } = useTranslation();
  const zoom = (fieldParam) => {
    setBuilderZoom(fieldParam);
  };

  const builderParent = () => {
    if (builderZoom) {
      const actualPath = findPathById(
        localMetaSpec,
        builderZoom?.blueprint?.id
      );

      const buffer = [];

      if (actualPath) {
        let parentFound = 0;
        actualPath.map((item, index) => {
          if (item === 'formBuilder') {
            buffer.push(false);
          } else if (item === 'elements') {
            parentFound += 1;
          } else if (parentFound === 1) {
            const parentPath = actualPath.slice(0, index + 1);
            const parentObject = get(localMetaSpec, parentPath);
            buffer.push(parentObject);
            parentFound -= 1;
          }
          return item;
        });

        return buffer[buffer.length - 2];
      }
    }
    return false;
  };

  const zoomOut = () => {
    // Get the before last (last is our current object)
    setBuilderZoom(builderParent());
  };

  const dynamicBreadcrumbs = () => {
    if (builderZoom) {
      const actualPath = findPathById(localMetaSpec, builderZoom.blueprint.id);

      const buffer = [];

      if (actualPath) {
        let parentFound = 0;
        actualPath.map((item, index) => {
          if (item === 'formBuilder') {
            buffer.push(
              <Button
                color='inherit'
                key={uuidv4()}
                onClick={() => {
                  setBuilderZoom(false);
                }}
              >
                {localMetaSpec.menus.formBuilder.title}
              </Button>
            );
          } else if (item === 'elements') {
            parentFound += 1;
          } else if (parentFound === 1) {
            const parentPath = actualPath.slice(0, index + 1);
            const parentObject = get(localMetaSpec, parentPath);
            let parentTitle = parentObject.blueprint.title;
            const parentData = parentObject.blueprint.actions.find(
              (x) => x.id === 'edit'
            )?.formData?.tabs?.data;
            if (parentData && parentData.label) {
              parentTitle = parentData.label;
            }
            buffer.push(
              <Button
                color='inherit'
                key={uuidv4()}
                onClick={() => {
                  setBuilderZoom(parentObject);
                }}
              >
                {parentTitle}
              </Button>
            );
            parentFound -= 1;
          }
          return item;
        });
      }

      buffer.pop();
      let menuTitle = menu.title;
      const parentData = menu?.actions?.find((x) => x.id === 'edit')?.formData
        ?.tabs?.data;
      if (parentData && parentData.label) {
        menuTitle = parentData.label;
      }
      buffer.push(
        <Typography color='textPrimary' key={uuidv4()}>
          {menuTitle?.toUpperCase()}
        </Typography>
      );

      return <Breadcrumbs aria-label='breadcrumb'>{buffer}</Breadcrumbs>;
    }
    return (
      <Typography color='textPrimary' key={uuidv4()}>
        {localMetaSpec.menus.formBuilder.title?.toUpperCase()}
      </Typography>
    );
  };

  const dynamicMenu = () => {
    const currentMenu = () => {
      if (
        menu.id === 'formBuilder' ||
        (builderZoom && builderZoom.blueprint.id === menu.id)
      ) {
        return true;
      }
      return false;
    };

    if (menu.droppable || menu.type === 'object') {
      return (
        <Droppable droppableId={menu.id} key={menu.id}>
          {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => {
            if (elements.length > 0) {
              return (
                <ListItem
                  className={classes.items}
                  key={menu.id + 1}
                  ref={innerRef}
                  {...droppableProps}
                  style={getListStyle(isDraggingOver)}
                >
                  {elements.map((field, index) => (
                    <Element
                      actions={{
                        ...actions,
                        zoom: (fieldParam, menuParam) =>
                          zoom(fieldParam, menuParam),
                        zoomOut: () => zoomOut(),
                      }}
                      classes={classes}
                      currentMenu={currentMenu()}
                      field={field}
                      index={index}
                      key={`parent-${field.blueprint.id}`}
                      localMetaSpec={localMetaSpec}
                      menu={menu}
                    />
                  ))}
                  {placeholder}
                </ListItem>
              );
            }
            return (
              <ListItem
                className={classes['empty-items']}
                key={menu.id + 1}
                ref={innerRef}
                {...droppableProps}
                style={getListStyle(isDraggingOver)}
              >
                <Typography variant='h4'>
                  {t('NeoBuilder.Edit.DropHere')}
                </Typography>
                {placeholder}
              </ListItem>
            );
          }}
        </Droppable>
      );
    }

    return (
      <Droppable
        isDropDisabled
        className={menu.classe}
        droppableId={menu.id}
        key={menu.id}
      >
        {({ innerRef, droppableProps, placeholder }) => {
          if (elements.length > 0) {
            return (
              <ListItem
                className={classes.items}
                key={menu.id}
                ref={innerRef}
                {...droppableProps}
              >
                {elements.map((field, index) => (
                  <Element
                    actions={{
                      ...actions,
                      zoom: (fieldParam, menuParam) =>
                        zoom(fieldParam, menuParam),
                      zoomOut: () => zoomOut(),
                    }}
                    classes={classes}
                    currentMenu={currentMenu()}
                    field={field.blueprint}
                    index={index}
                    key={`parent-${field.blueprint.id}`}
                    menu={menu}
                  />
                ))}
                {placeholder}
              </ListItem>
            );
          }
          return (
            <ListItem
              className={classes['empty-items']}
              key={menu.id + 1}
              ref={innerRef}
              {...droppableProps}
            >
              <Typography variant='h4'>
                {t('NeoBuilder.Edit.DropHere')}
              </Typography>
              {placeholder}
            </ListItem>
          );
        }}
      </Droppable>
    );
  };

  return (
    <List className={`${classes.root} ${classes[menu.class]}`}>
      <Grid container alignContent='space-between' alignItems='baseline'>
        {dynamicBreadcrumbs()}
        {ActionsGroup(
          menu,
          builderZoom,
          {
            ...actions,
            edit: () => actions.edit(builderZoom, builderParent()),
            zoomOut: () => zoomOut(),
          },
          ['edit', 'zoomOut']
        )}
      </Grid>
      {dynamicMenu()}
    </List>
  );
};

Builder.propTypes = {
  localMetaSpec: PropTypes.object.isRequired,
  builderZoom: PropTypes.any.isRequired,
  setBuilderZoom: PropTypes.func.isRequired,
  menu: PropTypes.object,
  elements: PropTypes.array,
  classes: PropTypes.object,
  actions: PropTypes.any,
};

export default Builder;
