/**
 * CTF: Ajout d'un champ à un client
 */
export default ({ editResponse }) => {
  let flagName = null;

  if (
    editResponse?.data?.options?.fields?.shared?.includes(
      'neolinkNumTelMobileFrancais'
    )
  ) {
    flagName = 'addSharedFieldToClient';
  }

  return flagName;
};
