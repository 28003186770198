import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import withUseResource from './Hoc/withUseResource';

function SimpleBreadcrumb({ resourceState, browser }) {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (resourceState.links.has('breadcrumb')) {
      setLinks(resourceState.links.getMany('breadcrumb'));
    }
  }, [resourceState]);

  if (!links.length) {
    return null;
  }

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {links.map((link, index) => {
        if (index === links.length - 1) {
          return (
            <Typography noWrap key={index} variant='button'>
              {link.title}
            </Typography>
          );
        }

        return (
          <Button key={index} onClick={() => browser.go(link.href)}>
            {link.title}
          </Button>
        );
      })}
    </Breadcrumbs>
  );
}

const Breadcrumb = withUseResource(SimpleBreadcrumb, 'Breadcrumb');

SimpleBreadcrumb.propTypes = {
  resourceState: PropTypes.object.isRequired,
  browser: PropTypes.object,
};

export default Breadcrumb;
