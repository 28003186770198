import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles'; // Make sure to update the import path
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  items: {
    display: 'flex',
  },
  cellTableHead: {
    fontWeight: 600,
  },
  itemElement: {
    display: 'flex',
  },
}));

function descendingComparator(a, b, orderBy) {
  const name = orderBy;

  if (b[name] < a[name]) {
    return -1;
  }
  if (b[name] > a[name]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const BasicList = ({
  tableMaxHeight,
  columns,
  content,
  defaultOrder,
  defaultOrderBy,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createSortHandler = (property) => (_event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer
        style={
          tableMaxHeight
            ? { maxHeight: `${tableMaxHeight}` }
            : { maxHeight: 480 }
        }
      >
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <TableCell
                    align={column.align}
                    className={classes.cellTableHead}
                    key={column.id}
                    sortDirection={orderBy === column.id ? order : false}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {content.length > 0
              ? stableSort(content, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow hover key={index} role='checkbox' tabIndex={-1}>
                      {columns.map((column) => {
                        let cellContent = '';
                        Object.entries(row).map(([key, value]) => {
                          if (key === column.id) {
                            cellContent = value;
                          }
                          return null;
                        });
                        return (
                          <TableCell align={column.align} key={column.id}>
                            {cellContent}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
              : [...Array(5)].map((x, i) => (
                  <TableRow hover key={i} role='checkbox' tabIndex={-1}>
                    {columns.map((column) => (
                      <TableCell align={column.align} key={column.id}>
                        <Skeleton
                          className={classes.skeletonField}
                          height={40}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={content.length}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} sur ${count}`
        }
        labelRowsPerPage='Lignes par page :'
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 100]}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

BasicList.defaultProps = {};

BasicList.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      minWidth: PropTypes.any,
      align: PropTypes.string,
    })
  ).isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.any,
    })
  ).isRequired,
  defaultOrder: PropTypes.string,
  defaultOrderBy: PropTypes.string,
  tableMaxHeight: PropTypes.number,
};

export default BasicList;
