import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSetRecoilState } from 'recoil';

import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FileCopy from '@mui/icons-material/FileCopy';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import snackbarAtom from '../../Atoms/snackbarAtom';

import BASE_URL from '../../Components/NeoBrowser/Utils/Config';
import ApiClient from '../../Components/NeoBrowser/Utils/ApiClient';
import NeoMarkdown from '../../Components/NeoMarkdown';

const useStyles = makeStyles({
  title: {
    marginBottom: 30,
  },
  alert: {
    alignItems: 'center',
  },
  select: {
    marginLeft: 5,
  },
  skeletonSelectRole: {
    margin: 8,
  },
  uriContainer: {
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  uuidTextField: {
    width: '40%',
  },
  uriTextField: {
    width: '80%',
  },
  copyButton: {
    marginLeft: 10,
  },
});

const FormEmbedView = ({ getToken, formId, uri }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const setAtomSnackbar = useSetRecoilState(snackbarAtom);

  const [dynamicUri, setDynamicUri] = useState('');
  const [published, setPublished] = useState(true);
  const [roles, setRoles] = useState([]);
  const [roleAs, setRoleAS] = useState(0);
  const [versions, setVersions] = useState(['latest']);
  const [chosenVersion, setChosenVersion] = useState(0);

  const updatedUri = () => {
    let newUri = BASE_URL + uri;
    newUri = newUri
      .replace('{version}', chosenVersion)
      .replace('{role}', roles[roleAs]);
    setDynamicUri(newUri);
  };

  useEffect(() => {
    updatedUri();
  }, [roles.length, roleAs, chosenVersion]);

  useEffect(() => {
    ApiClient.request({
      url: `/form/${formId}/revisions`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(async (response) => {
        const newVersions = [
          { value: 0, text: t('NeoBuilder.Embed.LatestVersion') },
        ];

        response?.data?.revisions.map((revision) => {
          if (revision?.published) {
            newVersions.push({
              value: revision.version,
              text: `version ${revision.version} ${t(
                'NeoBuilder.Embed.FixedVersion'
              )}`,
            });
          }
          return null;
        });
        if (newVersions.length === 1) {
          setPublished(false);
        }
        setVersions(newVersions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [uri]);

  useEffect(() => {
    if (versions.length > 1) {
      ApiClient.request({
        url:
          chosenVersion !== 0
            ? `/form/${formId}/roles?version=${chosenVersion}`
            : `/form/${formId}/roles`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then(async (response) => {
          setRoles(response?.data?.roles);
          if (roleAs >= roles.length) {
            setRoleAS(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [chosenVersion, versions.length]);

  const handleChangePreviewAs = (event) => {
    setRoleAS(event.target.value);
    updatedUri();
  };

  const handleChangeVersion = (event) => {
    setChosenVersion(event.target.value);
  };

  const renderEmbedingTutorial = () => {
    const toRender = [];

    if (roles.length > 0 && versions.length) {
      toRender.push(
        <Alert className={classes.alert} severity='info'>
          <Grid container alignItems='baseline'>
            <Typography>{t('NeoBuilder.Embed.UseVersion')}</Typography>
            <Select
              className={classes.select}
              id='select-version-used'
              value={chosenVersion}
              onChange={handleChangeVersion}
            >
              {versions.map((version, index) => (
                <MenuItem key={index} value={version.value}>
                  {version.text}
                </MenuItem>
              ))}
            </Select>
            <Typography>{t('NeoBuilder.Embed.WithRole')}</Typography>
            <Select
              className={classes.select}
              id='select-access-role-used'
              value={roleAs}
              onChange={handleChangePreviewAs}
            >
              {roles.map((role, index) => (
                <MenuItem key={index} value={index}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Alert>
      );
    } else {
      toRender.push(
        <Skeleton
          className={classes.skeletonSelectRole}
          height={60}
          variant='rect'
        />
      );
    }

    if (versions.length > 1) {
      if (dynamicUri && formId) {
        toRender.push(
          <>
            <Grid container className={classes.uriContainer}>
              <TextField
                disabled
                className={classes.uuidTextField}
                id='outlined-disabled'
                label='UUID'
                size='small'
                value={formId}
                variant='outlined'
              >
                {formId}
              </TextField>
              <CopyToClipboard text={formId}>
                <Button
                  className={classes.copyButton}
                  startIcon={<FileCopy />}
                  variant='contained'
                  onClick={() => {
                    setAtomSnackbar((prevAtomSnackbar) => ({
                      ...prevAtomSnackbar,
                      open: true,
                      text: t('Snackbar.CopiedToClipboard'),
                      severity: 'success',
                    }));
                  }}
                >
                  {t('NeoBuilder.Embed.CopyUuid')}
                </Button>
              </CopyToClipboard>
            </Grid>
            <Grid container className={classes.uriContainer}>
              <TextField
                disabled
                className={classes.uriTextField}
                id='outlined-disabled'
                label='URI'
                size='small'
                value={dynamicUri}
                variant='outlined'
              >
                {dynamicUri}
              </TextField>
              <CopyToClipboard text={dynamicUri}>
                <Button
                  className={classes.copyButton}
                  startIcon={<FileCopy />}
                  variant='contained'
                  onClick={() => {
                    setAtomSnackbar((prevAtomSnackbar) => ({
                      ...prevAtomSnackbar,
                      open: true,
                      text: t('Snackbar.CopiedToClipboard'),
                      severity: 'success',
                    }));
                  }}
                >
                  {t('NeoBuilder.Embed.CopyUri')}
                </Button>
              </CopyToClipboard>
            </Grid>
          </>
        );
      }
      toRender.push(
        <>
          <NeoMarkdown
            content={t('NeoBuilder.Embed.ReadMe', {
              snippet: t('NeoBuilder.Embed.Snippet', { uri: dynamicUri }),
            })}
          />
          <CopyToClipboard
            text={t('NeoBuilder.Embed.Snippet', { uri: dynamicUri })}
          >
            <Button
              className={classes.copyButton}
              startIcon={<FileCopy />}
              variant='contained'
              onClick={() => {
                setAtomSnackbar((prevAtomSnackbar) => ({
                  ...prevAtomSnackbar,
                  open: true,
                  text: t('Snackbar.CopiedToClipboard'),
                  severity: 'success',
                }));
              }}
            >
              {t('NeoBuilder.Embed.CopySnippet')}
            </Button>
          </CopyToClipboard>
        </>
      );
    }

    return toRender;
  };

  return (
    <>
      <Typography className={classes.title} variant='h4'>
        {t('NeoBuilder.Embed.Title')}
      </Typography>
      {published ? (
        renderEmbedingTutorial()
      ) : (
        <Alert className={classes.alert} severity='warning' variant='filled'>
          <Typography>{t('NeoBuilder.Embed.WarningNotPublished')}</Typography>
        </Alert>
      )}
    </>
  );
};

FormEmbedView.propTypes = {
  getToken: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};

export default FormEmbedView;
