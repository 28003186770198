import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import ApiClient from '../../Components/NeoBrowser/Utils/ApiClient';
import NeoFormHosted from '../../Components/NeoForm/NeoFormHosted';
import BASE_URL from '../../Components/NeoBrowser/Utils/Config';

const useStyles = makeStyles({
  alert: {
    alignItems: 'center',
  },
  select: {
    marginLeft: 5,
  },
  skeletonSelectRole: {
    margin: 8,
  },
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FormPreviewView = ({ apiUserService }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const query = useQuery();
  const [previewAs, setPreviewAs] = useState(0);
  const [roles, setRoles] = useState([]);
  const formId = query.get('formId');
  const version = +query.get('version');

  useEffect(() => {
    ApiClient.request({
      url: version
        ? `/form/${formId}/roles?version=${version}`
        : `/form/${formId}/roles`,
      headers: {
        Authorization: `Bearer ${apiUserService.getToken()}`,
      },
    })
      .then(async (response) => {
        setRoles(response?.data?.roles);
        setPreviewAs(0);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [formId, version]);

  const handleChangePreviewAs = (event) => {
    setPreviewAs(event.target.value);
  };

  return (
    <>
      {roles.length > 0 ? (
        <>
          <Alert className={classes.alert} severity='info'>
            <Grid container alignItems='baseline'>
              <Typography>{t('NeoBuilder.Preview.PreviewAs')}</Typography>
              <Select
                className={classes.select}
                id='select-preview-access-role'
                value={previewAs}
                onChange={handleChangePreviewAs}
              >
                {roles.map((role, index) => (
                  <MenuItem key={index} value={index}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
              <Typography>
                .{' '}
                {t('NeoBuilder.Preview.PreviewVersionX', {
                  version:
                    version > 0
                      ? `${t(
                          'NeoBuilder.Revisions.Version'
                        ).toLowerCase()} ${version}`
                      : t('NeoBuilder.Embed.LatestVersion'),
                })}
              </Typography>
            </Grid>
          </Alert>
          <NeoFormHosted
            formData={{}}
            handleSubmit={() => {}}
            submitName='SUBMIT'
            token={apiUserService.getToken()}
            uri={`${BASE_URL}/form/${formId}?role=${
              roles?.[previewAs] ? roles[previewAs] : ''
            }&version=${version || 0}`}
          />
        </>
      ) : (
        <Skeleton
          className={classes.skeletonSelectRole}
          height={60}
          variant='rect'
        />
      )}
    </>
  );
};

FormPreviewView.propTypes = {
  apiUserService: PropTypes.object,
};

export default FormPreviewView;
