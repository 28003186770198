import React, { useState, useEffect, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useSetRecoilState } from 'recoil';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import snackbarAtom from '../../../Atoms/snackbarAtom';
import PopupDialog from '../../PopupDialog';
import BasicList from '../../List';
import NeoFormUse from '../NeoFormUse';

const NeoFormRevision = ({
  formId,
  getFormRevision,
  getBuilderSchema,
  handlePublishVersion,
  localMetaSpecParent,
  setFormPublished,
}) => {
  const { t } = useTranslation();
  const [content, setContent] = useState([]);
  const [publish, setPublish] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});
  const setAtomSnackbar = useSetRecoilState(snackbarAtom);

  const { localMetaSpec, setLocalMetaSpec } = localMetaSpecParent;

  const publishDialogSchema = {
    title: t('NeoBuilder.Revisions.Publish.ConfirmationTitle'),
    type: 'object',
    required: ['note'],
    properties: {
      warning: {
        type: 'string',
        title: 'warning',
        description: t('NeoBuilder.Revisions.Publish.WarningDescription'),
      },
      note: {
        title: t('NeoBuilder.Revisions.Publish.NoteTitle'),
        type: 'string',
      },
    },
  };

  const publishDialogUiSchema = {
    warning: {
      'ui:field': 'NeoAlertMessage',
    },
    note: {
      'ui:widget': 'textarea',
    },
    'ui:order': ['warning', 'note'],
  };

  useEffect(async () => {
    setContent(await getFormRevision(formId));
  }, [publish]);

  const restoreToFormVersion = async (version) => {
    const response = await getBuilderSchema(version);

    if (response?.rawSchema) {
      const newBuildeSchema = localMetaSpec.menus;
      newBuildeSchema.formBuilder = response?.rawSchema;
      localMetaSpec.updateTimestamp = Date.now();
      setLocalMetaSpec(cloneDeep(localMetaSpec));
      setAtomSnackbar((prevAtomSnackbar) => ({
        ...prevAtomSnackbar,
        open: true,
        text: t('Snackbar.FormRestored', { version }),
        severity: 'success',
      }));
    }
  };

  const parseRevisionList = (list) => {
    const parsed = list.map((element) => {
      const newElement = element;
      if (element.published) {
        newElement.actions = (
          <Grid container justifyContent='space-evenly'>
            <Button
              color='secondary'
              href={`/preview?formId=${formId}&version=${element.version}`}
              target='_blank'
              variant='contained'
            >
              {t('NeoBuilder.Revisions.Actions.Preview')}
            </Button>
            <Button
              color='secondary'
              variant='contained'
              onClick={() => {
                restoreToFormVersion(element.version);
              }}
            >
              {t('NeoBuilder.Revisions.Actions.Restore')}
            </Button>
          </Grid>
        );
      } else {
        newElement.actions = (
          <Grid container justifyContent='space-evenly'>
            <Button
              color='primary'
              startIcon={<SendIcon />}
              variant='contained'
              onClick={async () => {
                const submitFormRef = createRef();

                setDialogConfig({
                  title: '',
                  content: (
                    <NeoFormUse
                      builder
                      customSubmit={
                        <Button
                          ref={submitFormRef}
                          style={{ display: 'none' }}
                          type='submit'
                        >
                          submitForm
                        </Button>
                      }
                      extensions={[]}
                      formDataReceived={{}}
                      handleSubmit={async (result, _jsonSchema) => {
                        await handlePublishVersion(result);
                        setPublish(!publish);
                        setFormPublished(true);
                        setDialogOpen(false);
                      }}
                      JsonSchema={publishDialogSchema}
                      UiSchema={publishDialogUiSchema}
                    />
                  ),
                  actions: (
                    <>
                      <Button
                        color='primary'
                        onClick={() => {
                          setDialogOpen(false);
                        }}
                      >
                        {t('Global.Cancel')}
                      </Button>
                      <Button
                        color='primary'
                        onClick={() => {
                          submitFormRef.current.click();
                        }}
                      >
                        {t('Global.Submit')}
                      </Button>
                    </>
                  ),
                });
                setDialogOpen(true);
              }}
            >
              {t('NeoBuilder.Revisions.Actions.Publish')}
            </Button>
          </Grid>
        );
      }
      if (moment(element.date, 'X', true).isValid()) {
        newElement.date = moment(element.date).format(t('Date.Format'));
      }
      return newElement;
    });

    return parsed;
  };

  return (
    <>
      <BasicList
        columns={[
          {
            id: 'version',
            label: t('NeoBuilder.Revisions.Version'),
          },
          {
            id: 'date',
            label: t('NeoBuilder.Revisions.Date'),
            align: 'center',
          },
          { id: 'user', label: t('NeoBuilder.Revisions.User') },
          { id: 'note', label: t('NeoBuilder.Revisions.Note'), minWidth: 150 },
          {
            id: 'actions',
            label: t('NeoBuilder.Revisions.Actions.Title'),
            align: 'center',
            minWidth: 250,
          },
        ]}
        content={parseRevisionList(content)}
        defaultOrder='desc'
        defaultOrderBy='version'
      />
      <PopupDialog config={dialogConfig} open={dialogOpen} />
    </>
  );
};

NeoFormRevision.propTypes = {
  formId: PropTypes.string,
  localMetaSpecParent: PropTypes.shape({
    localMetaSpec: PropTypes.object.isRequired,
    setLocalMetaSpec: PropTypes.func.isRequired,
  }).isRequired,
  getFormRevision: PropTypes.func.isRequired,
  getBuilderSchema: PropTypes.func.isRequired,
  handlePublishVersion: PropTypes.func.isRequired,
  setFormPublished: PropTypes.func.isRequired,
};

export default NeoFormRevision;
