import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import { useRecoilValue } from 'recoil';
import keycloakAtom from '../../../Atoms/keycloakAtom';

import NeoFormLocal from '../NeoFormHosted/NeoFormLocal';

const useStyles = makeStyles({
  alert: {
    alignItems: 'center',
  },
  select: {
    marginLeft: 5,
  },
});

const NeoFormPreview = ({ formId, BuilderSchema, roles, handleSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [previewAs, setPreviewAs] = useState(0);
  const kc = useRecoilValue(keycloakAtom);

  const handleChangePreviewAs = (event) => {
    setPreviewAs(event.target.value);
  };

  return (
    <>
      <Alert className={classes.alert} severity='info'>
        <Grid container alignItems='baseline'>
          <Typography>{t('NeoBuilder.Preview.PreviewAs')}</Typography>
          <Select
            className={classes.select}
            id='select-preview-access-role'
            value={previewAs}
            onChange={handleChangePreviewAs}
          >
            {roles.map((role, index) => (
              <MenuItem key={index} value={index}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Alert>

      <NeoFormLocal
        BuilderSchema={BuilderSchema}
        formId={formId}
        handleSubmit={handleSubmit}
        id={formId}
        token={kc.token}
        userRole={roles[previewAs]}
      />
    </>
  );
};

NeoFormPreview.propTypes = {
  formId: PropTypes.string,
  BuilderSchema: PropTypes.object.isRequired,
  roles: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
};

export default NeoFormPreview;
