import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

const NeoBuilderStyle = makeStyles((theme) => ({
  'alert-unsaved': {
    marginBottom: 24,
  },
  'container-form-header': {
    margin: 16,
  },
  'container-form-info': {
    display: 'flex',
    flexWrap: 'wrap',
  },
  apiPath: {
    minWidth: 400,
    marginLeft: 16,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: 16,
    },
  },
  cancelButton: {
    marginRight: 16,
  },
  saveButton: {
    marginRight: 32,
  },
  'container-form-actions': {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      placeItems: 'flex-end',
      marginTop: 16,
    },
  },
  root: {
    width: '100%',
    minWidth: 360,
    margin: 8,
    padding: 4,
    border: '1px solid lightgrey',
    borderRadius: 2,
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0,
  },
  'small-menu': {
    flex: 2,
  },
  'large-menu': {
    flex: 8,
  },
  accordion: {
    backgroundColor: theme.palette.grey[100],
    flexDirection: 'column',
    alignContent: 'right',
    width: '100%',
  },
  items: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'right',
  },
  'empty-items': {
    border: `dashed ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.grey[100],
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'right',
  },
  'element-chip': {
    margin: 4,
  },
  element: {
    width: '100%',
    minWidth: 320,
    minHeight: 40,
    border: '1px solid lightgrey',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    padding: 4,
    margin: 2,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  'droppable-area': {
    width: '100%',
    minHeight: 80,
    backgroundColor: grey[100],
  },
}));

export default NeoBuilderStyle;
